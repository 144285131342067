<template>
  <div class="row mt-4 mb-4">
    <div class="col-12">
      <h2>{{ t("product_accountability") }}</h2>
    </div>
    <div class="col-12">
      <table class="table tablesorter table align-items-center table-flush">
        <thead>
          <tr>
            <th>{{ t("pa_no") }}</th>
            <th>{{ t("pa_dose") }}</th>
            <th>{{ t("pa_date_of_dispersion") }}</th>
            <th>{{ t("pa_dispersed_amount") }}</th>
            <th>{{ t("pa_date_of_return") }}</th>
            <th>{{ t("pa_amount_returned") }}</th>
            <th></th>
          </tr>
        </thead>
        <single-pa
          :key="keyPA"
          :pa="productAccontability"
          @remove="remove(keyPA)"
          v-for="(productAccontability, keyPA) in product_accountability"
        />
      </table>
      <base-button
        outline
        type="primary"
        icon="ni ni-fat-add"
        @click="addPA()"
        class="mt-2"
      >
        {{ t("button_add") }}
      </base-button>
    </div>
  </div>
</template>
<script>
import SinglePa from "./singlePA.vue";
import { mapState } from "vuex";
export default {
  name: "ProductAccontability",
  data() {
    return {};
  },
  components: {
    SinglePa,
  },
  props: {
    product_accountability: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    addPA() {
      this.product_accountability.push({
        no: null,
        dose: null,
        date_dispersion: null,
        amount_dispersion: null,
        date_return: null,
        amount_return: null,
      });
    },
    remove(key) {
      this.product_accountability.splice(key, 1);
    },
  },
  created() {
    if (this.product_accountability.length === 0) this.addPA();
  },
};
</script>
