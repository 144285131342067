<template>
  <tbody class="list">
    <tr>
      <td colspan="4">
        <base-input
        :label="t('AdverseEventName')"
        v-model="sae.name"
      >
      </base-input>
      </td>
    </tr>
    <tr>
      <td>
        <base-radio dataValue="1" v-model="sae.intensity">
          {{ t(" Mild/Grade I") }}
        </base-radio>
        <base-radio dataValue="2" v-model="sae.intensity">
          {{ t(" Moderate/Grade II") }}
        </base-radio>
        <base-radio dataValue="3" v-model="sae.intensity">
          {{ t(" Severe/Grade III") }}
        </base-radio>
        <base-radio dataValue="4" v-model="sae.intensity">
          {{ t(" Very Severe/Grade IV") }}
        </base-radio>
      </td>
      <td>
        <base-radio dataValue="1" v-model="sae.casual_relationship_studty_drug">
          {{ t("Definitely") }}
        </base-radio>
        <base-radio dataValue="2" v-model="sae.casual_relationship_studty_drug">
          {{ t(" Probably") }}
        </base-radio>
        <base-radio dataValue="3" v-model="sae.casual_relationship_studty_drug">
          {{ t("Possibly") }}
        </base-radio>
        <base-radio dataValue="4" v-model="sae.casual_relationship_studty_drug">
          {{ t("Unlikely related") }}
        </base-radio>
        <base-radio dataValue="5" v-model="sae.casual_relationship_studty_drug">
          {{ t("Not related") }}
        </base-radio>
      </td>
      <td>
        <base-radio dataValue="1" v-model="sae.precaution_studty_drug">
          {{ t("Require no precaution") }}
        </base-radio>
        <base-radio dataValue="2" v-model="sae.precaution_studty_drug">
          {{ t(" Drug dose is decreased") }}
        </base-radio>
        <base-radio dataValue="3" v-model="sae.precaution_studty_drug">
          {{ t("Drug dose is increased") }}
        </base-radio>
        <base-radio dataValue="4" v-model="sae.precaution_studty_drug">
          {{ t("Drug is interrupted") }}
        </base-radio>
        <base-radio dataValue="5" v-model="sae.precaution_studty_drug">
          {{ t("Drug withdrawal") }}
        </base-radio>
      </td>
      <td>
        <base-radio dataValue="0" v-model="sae.required">
          {{ t("no") }}
        </base-radio>
        <base-radio dataValue="1" v-model="sae.required">
          {{ t("yes") }}
        </base-radio>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <base-date-input
          :label="t('Initiation Date')"
          v-model="sae.initiation_date"
        >
        </base-date-input>
      </td>
      <td colspan="2">
        <base-date-input
          :label="t('Recovery Date')"
          v-model="sae.recovery_date"
        >
        </base-date-input>
      </td>
    </tr>
  </tbody>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "single-sae",
  data() {
    return {};
  },
  components: {},
  props: {
    sae: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {},
  created() {},
};
</script>
