<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ t("IBS-QoL Questionnaire") }}</h2>
    </div>
    <is-completed v-model="questionnaire" :label="t('Is IBS-QoL Completed?')" />
    <questionnaire-question
      v-model="questionnaire.f_1"
      :label="'1. ' + t('questionnaire_f_1')"
    />
    <questionnaire-question
      v-model="questionnaire.f_2"
      :label="'2. ' + t('questionnaire_f_2')"
    />
    <questionnaire-question
      v-model="questionnaire.f_3"
      :label="'3. ' + t('questionnaire_f_3')"
      :extremlyStatus="false"
    />
    <questionnaire-question
      v-model="questionnaire.f_4"
      :label="'4. ' + t('questionnaire_f_4')"
    />
    <questionnaire-question
      v-model="questionnaire.f_5"
      :label="'5. ' + t('questionnaire_f_5')"
      :extremlyStatus="false"
    />
    <questionnaire-question
      v-model="questionnaire.f_6"
      :label="'6. ' + t('questionnaire_f_6')"
      :extremlyStatus="false"
    />
    <questionnaire-question
      v-model="questionnaire.f_7"
      :label="'7. ' + t('questionnaire_f_7')"
      :extremlyStatus="false"
    />
    <questionnaire-question
      v-model="questionnaire.f_8"
      :label="'8. ' + t('questionnaire_f_8')"
    />
    <questionnaire-question
      v-model="questionnaire.f_9"
      :label="'9. ' + t('questionnaire_f_9')"
    />
    <questionnaire-question
      v-model="questionnaire.f_10"
      :label="'10. ' + t('questionnaire_f_10')"
    />
    <questionnaire-question
      v-model="questionnaire.f_11"
      :label="'11. ' + t('questionnaire_f_11')"
    />
    <questionnaire-question
      v-model="questionnaire.f_12"
      :label="'12. ' + t('questionnaire_f_12')"
    />
  </div>
</template>
<script>
import QuestionnaireQuestion from "./partials/QuestionnaireQuestion";
import IsCompleted from "./partials/isCompleted.vue";
import { mapState } from "vuex";
export default {
  name: "questionnaire",
  data() {
    return {};
  },
  components: {
    QuestionnaireQuestion,
    IsCompleted,
  },
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {},
  created() {},
};
</script>
