<template>
  <div class="row my-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h2>{{ t("Inclusion Criteria") }}</h2>
          <p class="ml-1">
            {{
              t(
                "Potential participants must meet the following inclusion criteria to be eligible for the study:"
              )
            }}
          </p>
        </div>
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_1"
          :label="t('field_1')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_2"
          :label="t('field_2')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_3"
          :label="t('field_3')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_3_1"
          :label="t('field_3_1')"
          class="pl-6"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_3_2"
          :label="t('field_3_2')"
          class="pl-6"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_3_3"
          :label="t('field_3_3')"
          class="pl-6"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_3_4"
          :label="t('field_3_4')"
          class="pl-6"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_4"
          :label="t('field_4')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_5"
          :label="t('field_5')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_6"
          :label="t('field_6')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_7"
          :label="t('field_7')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_8"
          :label="t('field_8')"
        />
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h2>{{ t("Exclusion Criteria") }}</h2>
          <p class="ml-1">
            {{
              t(
                "Potential participants must not meet any of the following criteria to be eligible for the study:"
              )
            }}
          </p>
        </div>
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_9"
          :label="t('field_9')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_10"
          :label="t('field_10')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_11"
          :label="t('field_11')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_12"
          :label="t('field_12')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_13"
          :label="t('field_13')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_14"
          :label="t('field_14')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_15"
          :label="t('field_15')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_16"
          :label="t('field_16')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_17"
          :label="t('field_17')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_18"
          :label="t('field_18')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_19"
          :label="t('field_19')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_100"
          :label="t('field_100')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_20"
          :label="t('field_20')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_101"
          :label="t('field_101')"
        />
        <patient-boolean
          :disabled="disabled"
          v-model="model.field_102"
          :label="t('field_102')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import patientBoolean from "./partials/patientBoolean";
export default {
  name: "criteria",
  data() {
    return {};
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    patientBoolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {},
  created() {},
};
</script>
