<template>
  <div class="row appointment my-4">
    <div class="col-12">
      <h2>{{ t("reminder_phone_call") }}</h2>
    </div>
    <div class="col-12 col-md-6">
      <patient-boolean
        classes="px-0"
        :label="t('appointment')"
        v-model="value.subject_confirm_appointment"
      />
    </div>
    <div class="col-12 col-md-6">
      <base-date-input
        :label="t('date')"
        v-model="value.subject_confirm_appointment_date"
        inline
      >
      </base-date-input>
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./patientBoolean.vue";

export default {
  name: "appointment",
  components: {
    PatientBoolean,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
