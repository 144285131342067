<template>
  <div class="row mb-4">
    <div class="col-12">
      <table class="table tablesorter table align-items-center table-flush">
        <thead>
          <tr>
            <th>{{ t("condution") }}</th>
            <th>{{ t("onset_date") }}</th>
            <th>{{ t("resolution_date") }}</th>
            <th>{{ t("ongoing") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="list">
          <single-medical-history
            :key="key"
            :medicalHistory="medicalHistory"
            @remove="remove(key)"
            v-for="(medicalHistory, key) in value"
          />
        </tbody>
      </table>
      <base-button
        outline
        type="primary"
        icon="ni ni-fat-add"
        @click="addMh()"
        class="mb-4"
      >
        {{ t("button_add") }}
      </base-button>
    </div>
  </div>
</template>
<script>
import SingleMedicalHistory from "./SingleMedicalHistory";
import { mapState } from "vuex";
export default {
  name: "medicalHistories",
  data() {
    return {};
  },
  components: {
    SingleMedicalHistory,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    addMh() {
      this.value.push({
        condution: null,
        onset_date: null,
        resolution_date: null,
        ongoing: null,
      });
    },
    remove(key) {
      this.value.splice(key, 1);
    },
  },
  created() {
    if (this.value.length === 0) {
      this.addMh();
    }
  },
};
</script>
