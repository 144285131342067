<template>
  <tr>
    <td>
      <base-input v-model="medicalHistory.condution"> </base-input>
    </td>
    <td>
      <base-date-input v-model="medicalHistory.onset_date"> </base-date-input>
    </td>
    <td>
      <base-date-input v-model="medicalHistory.resolution_date">
      </base-date-input>
    </td>
    <td>
      <patient-boolean v-model="medicalHistory.ongoing" />
    </td>
    <td>
      <base-button
        outline
        type="danger"
        icon="ni ni-fat-remove"
        size="sm"
        @click="$emit('remove')"
      >
      </base-button>
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
import PatientBoolean from "./partials/patientBoolean.vue";
export default {
  name: "singleMedicalHistory",
  data() {
    return {};
  },
  components: {
    PatientBoolean,
  },
  props: {
    medicalHistory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {},
  created() {},
};
</script>
