<template>
  <div class="overflow-x-scroll my-4 lab-results">
    <h2>{{ t("lab") }}</h2>
    <table class="table tablesorter table align-items-center table-flush">
      <thead>
        <tr>
          <th>{{ t("test") }}</th>
          <th style="min-width: 130px">{{ t("result") }}</th>
          <th>{{ t("unit") }}</th>
          <th style="max-width: 190px">{{ t("expected_range") }}</th>
          <th>{{ t("clinically_significant") }}</th>
          <th>{{ t("comment") }}</th>
        </tr>
      </thead>
      <tbody class="list">
        <tr>
          <th>{{ t("White blood cell count") }}</th>
          <td>
            <base-input v-model="lab.white_blood_cell_count" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(mm3)") }}</td>
          <td>
            <patient-boolean v-model="lab.white_blood_cell_count_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.white_blood_cell_count_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.white_blood_cell_count_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.white_blood_cell_count_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("MCV") }}</th>
          <td>
            <base-input v-model="lab.mcv" inputType="number"> </base-input>
          </td>
          <td>{{ t("(fl)") }}</td>
          <td>
            <patient-boolean v-model="lab.mcv_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.mcv_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.mcv_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.mcv_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Hemoglobin") }}</th>
          <td>
            <base-input v-model="lab.hemoglobin" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(g/dL)") }}</td>
          <td>
            <patient-boolean v-model="lab.hemoglobin_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.hemoglobin_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.hemoglobin_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.hemoglobin_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("MCH") }}</th>
          <td>
            <base-input v-model="lab.mch" inputType="number"> </base-input>
          </td>
          <td>{{ t("(pg)") }}</td>
          <td>
            <patient-boolean v-model="lab.mch_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.mch_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.mch_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.mch_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Hematocrit") }}</th>
          <td>
            <base-input v-model="lab.hematocrit" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(%)") }}</td>
          <td>
            <patient-boolean v-model="lab.hematocrit_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.hematocrit_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.hematocrit_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.hematocrit_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("MCHC") }}</th>
          <td>
            <base-input v-model="lab.mchc" inputType="number"> </base-input>
          </td>
          <td>{{ t("(g/dL)") }}</td>
          <td>
            <patient-boolean v-model="lab.mchc_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.mchc_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.mchc_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.mchc_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("RBC") }}</th>
          <td>
            <base-input v-model="lab.rbc" inputType="number"> </base-input>
          </td>
          <td>{{ t("(million/mm3)") }}</td>
          <td>
            <patient-boolean v-model="lab.rbc_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.rbc_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.rbc_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.rbc_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Platelets") }}</th>
          <td>
            <base-input v-model="lab.platelets" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(mm3)") }}</td>
          <td>
            <patient-boolean v-model="lab.platelets_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.platelets_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.platelets_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.platelets_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Sedimentation (ESR)") }}</th>
          <td>
            <base-input v-model="lab.sedimentation" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(mm/hour)") }}</td>
          <td>
            <patient-boolean v-model="lab.sedimentation_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.sedimentation_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.sedimentation_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.sedimentation_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("RDW") }}</th>
          <td>
            <base-input v-model="lab.rdw" inputType="number"> </base-input>
          </td>
          <td>{{ t("%") }}</td>
          <td>
            <patient-boolean v-model="lab.rdw_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.rdw_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.rdw_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.rdw_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("CRP (latex)") }}</th>
          <td>
            <base-input v-model="lab.crp" inputType="number"> </base-input>
          </td>
          <td>{{ t("(mg/L)") }}</td>
          <td>
            <patient-boolean v-model="lab.crp_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.crp_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.crp_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.crp_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("ALT") }}</th>
          <td>
            <base-input v-model="lab.alt" inputType="number"> </base-input>
          </td>
          <td>{{ t("IU/L") }}</td>
          <td>
            <patient-boolean v-model="lab.alt_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.alt_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.alt_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.alt_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("AST") }}</th>
          <td>
            <base-input v-model="lab.ast" inputType="number"> </base-input>
          </td>
          <td>{{ t("(IU/L)") }}</td>
          <td>
            <patient-boolean v-model="lab.ast_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.ast_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.ast_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.ast_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("GGT") }}</th>
          <td>
            <base-input v-model="lab.ggt" inputType="number"> </base-input>
          </td>
          <td>{{ t("(U/L)") }}</td>
          <td>
            <patient-boolean v-model="lab.ggt_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.ggt_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.ggt_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.ggt_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("ALP") }}</th>
          <td>
            <base-input v-model="lab.alp" inputType="number"> </base-input>
          </td>
          <td>{{ t("(IU/L)") }}</td>
          <td>
            <patient-boolean v-model="lab.alp_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.alp_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.alp_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.alp_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("BUN") }}</th>
          <td>
            <base-input v-model="lab.bun" inputType="number"> </base-input>
          </td>
          <td>{{ t("mg/dL") }}</td>
          <td>
            <patient-boolean v-model="lab.bun_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.bun_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.bun_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.bun_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Creatinine") }}</th>
          <td>
            <base-input v-model="lab.creatinine" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(mg/dL)") }}</td>
          <td>
            <patient-boolean v-model="lab.creatinine_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.creatinine_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.creatinine_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.creatinine_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Calcium") }}</th>
          <td>
            <base-input v-model="lab.calcium" inputType="number"> </base-input>
          </td>
          <td>{{ t("mg/dL") }}</td>
          <td>
            <patient-boolean v-model="lab.calcium_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.calcium_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.calcium_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.calcium_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Potassium") }}</th>
          <td>
            <base-input v-model="lab.potassium" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(mEq/L)") }}</td>
          <td>
            <patient-boolean v-model="lab.potassium_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.potassium_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.potassium_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.potassium_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Sodium") }}</th>
          <td>
            <base-input v-model="lab.sodium" inputType="number"> </base-input>
          </td>
          <td>{{ t("mEq/L") }}</td>
          <td>
            <patient-boolean v-model="lab.sodium_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.sodium_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.sodium_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.sodium_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Chloride") }}</th>
          <td>
            <base-input v-model="lab.chloride" inputType="number"> </base-input>
          </td>
          <td>{{ t("(mEq/L)") }}</td>
          <td>
            <patient-boolean v-model="lab.chloride_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.chloride_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.chloride_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.chloride_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Free T3") }}</th>
          <td>
            <base-input v-model="lab.free_t3" inputType="number"> </base-input>
          </td>
          <td>{{ t("ng/dL") }}</td>
          <td>
            <patient-boolean v-model="lab.free_t3_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.free_t3_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.free_t3_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.free_t3_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Free T4") }}</th>
          <td>
            <base-input v-model="lab.free_t4" inputType="number"> </base-input>
          </td>
          <td>{{ t("(ng/dL)") }}</td>
          <td>
            <patient-boolean v-model="lab.free_t4_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.free_t4_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.free_t4_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.free_t4_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("TSH") }}</th>
          <td>
            <base-input v-model="lab.tsh" inputType="number"> </base-input>
          </td>
          <td>{{ t("mIU/L") }}</td>
          <td>
            <patient-boolean v-model="lab.tsh_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.tsh_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.tsh_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.tsh_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Anti-gliadin IgA") }}</th>
          <td>
            <base-input v-model="lab.anti_gliadin_iga" inputType="number">
            </base-input>
          </td>
          <td>20 u/mL</td>
          <td>
            <patient-boolean v-model="lab.anti_gliadin_iga_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.anti_gliadin_iga_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.anti_gliadin_iga_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.anti_gliadin_iga_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Tissue transglutaminase IgA") }}</th>
          <td>
            <base-input
              v-model="lab.tissue_transglutaminase_iga"
              inputType="number"
            >
            </base-input>
          </td>
          <td>40U/mL</td>
          <td>
            <patient-boolean v-model="lab.tissue_transglutaminase_iga_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.tissue_transglutaminase_iga_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.tissue_transglutaminase_iga_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.tissue_transglutaminase_iga_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Fecal calprotectin") }}</th>
          <td>
            <base-input v-model="lab.fecal_calprotectin" inputType="number">
            </base-input>
          </td>
          <td>{{ t("(µg/mg)") }}</td>
          <td>
            <patient-boolean v-model="lab.fecal_calprotectin_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.fecal_calprotectin_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.fecal_calprotectin_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.fecal_calprotectin_comment" />
          </td>
        </tr>
        <tr>
          <th>{{ t("Pregnancy test") }}</th>
          <td>
            <base-input v-model="lab.pregnancy_test"> </base-input>
          </td>
          <td>{{ t("urine") }}</td>
          <td>
            <patient-boolean v-model="lab.pregnancy_test_range" />
          </td>
          <td>
            <base-radio
              dataValue="cs"
              v-model="lab.pregnancy_test_significant"
              class="mr-4"
            >
              {{ t("cs") }}
            </base-radio>
            <base-radio
              dataValue="ncs"
              v-model="lab.pregnancy_test_significant"
              class="mr-4"
            >
              {{ t("ncs") }}
            </base-radio>
          </td>
          <td>
            <base-input v-model="lab.pregnancy_test_comment" />
          </td>
        </tr>
        <tr>
          <td colspan="6" class="ecg">
            <base-input :label="t('ECG')" v-model="lab.ecg"> </base-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PatientBoolean from "./partials/patientBoolean.vue";
export default {
  name: "lab",
  data() {
    return {};
  },
  components: {
    PatientBoolean,
  },
  props: {
    lab: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss">
.lab-results {
  .form-control-label {
    display: none;
  }
  .ecg {
    .form-control-label {
      display: block !important;
    }
  }
  tr {
    td {
      // vertical-align: top;
      &:nth-child(4) {
        padding: 0;
        .col-12 {
          padding: 0;
        }
      }
    }
  }
}
</style>