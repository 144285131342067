<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-3 text-white">{{ t("register_form") }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <base-loader
              v-if="
                $wait.is('fetchPatient') ||
                $wait.is('deletePatient') ||
                $wait.is('lockStatusChange')
              "
            />
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <h3 class="mb-0">
                    <span v-if="pageType === 'edit'"
                      >{{ patient | patientName }} |
                      {{ patient.patient_no }}</span
                    >
                    <span v-if="pageType === 'new'">{{
                      t("new_patient")
                    }}</span>
                  </h3>
                </div>
                <div class="col-12 col-md-4 text-right">
                  <base-button
                    outline
                    type="warning"
                    icon="ni ni-fat-remove"
                    size="sm"
                    :disabled="lockStatus"
                    @click="patientDeleteModal = true"
                    v-if="
                      pageType === 'edit' &&
                      !['VIEWER', 'EDITOR'].includes(user.level)
                    "
                  ></base-button>
                </div>
              </div>
            </div>
            <tabs
              fill
              class="flex-column flex-md-row"
              v-if="!$wait.is('fetchPatient')"
            >
              <card shadow>
                <ValidationObserver v-slot="{ invalid }">
                  <form @submit.prevent="save" class="position-relative">
                    <tab-pane title="Patient Info">
                      <patient-info
                        :patient="patient"
                        :lockStatus="lockStatus"
                        @updateLockStatus="
                          updateLockStatus($event, 'is_locked')
                        "
                      />
                    </tab-pane>
                    <tab-pane :title="t('demographic')">
                      <demographic
                        :patient="patient"
                        :lockStatus="lockStatus"
                      />
                    </tab-pane>
                    <tab-pane title="Visit 0">
                      <visit0
                        :patient="patient"
                        :visit_0="visit_0"
                        :lockStatus="visit0LockStatus"
                        @updateLockStatus="
                          updateLockStatus($event, 'visit_0_is_locked')
                        "
                      />
                    </tab-pane>
                    <tab-pane title="Visit 1">
                      <visit1
                        :visit_1="visit_1"
                        :lockStatus="visit1LockStatus"
                        :patient="patient"
                        @updateLockStatus="
                          updateLockStatus($event, 'visit_1_is_locked')
                        "
                      />
                    </tab-pane>
                    <tab-pane title="Visit 2">
                      <visit2
                        :visit_2="visit_2"
                        :lockStatus="visit2LockStatus"
                        :patient="patient"
                        @updateLockStatus="
                          updateLockStatus($event, 'visit_2_is_locked')
                        "
                      />
                    </tab-pane>
                    <tab-pane title="Visit 3">
                      <visit3
                        :visit_3="visit_3"
                        :lockStatus="visit3LockStatus"
                        :patient="patient"
                        @updateLockStatus="
                          updateLockStatus($event, 'visit_3_is_locked')
                        "
                      />
                    </tab-pane>
                    <tab-pane title="Visit 4">
                      <visit4
                        :visit_4="visit_4"
                        :lockStatus="visit4LockStatus"
                        :patient="patient"
                        @updateLockStatus="
                          updateLockStatus($event, 'visit_4_is_locked')
                        "
                      />
                    </tab-pane>
                    <tab-pane title="End of Treatment">
                      <EndOfTreatment :patient="patient" />
                    </tab-pane>
                    <hr />
                    <div class="d-flex justify-content-end">
                      <base-button
                        type="success"
                        class="my-4"
                        nativeType="submit"
                        :disabled="
                          invalid ||
                          $wait.is('createPatient') ||
                          $wait.is('updatePatient') ||
                          approvement
                        "
                        v-if="user.level !== 'VIEWER'"
                      >
                        {{ t("button_save") }}
                      </base-button>
                    </div>
                  </form>
                </ValidationObserver>
              </card>
            </tabs>
          </card>
        </div>
      </div>
    </div>
    <delete-confirm-modal
      :modalStatus.sync="patientDeleteModal"
      :id="patient.id"
      @confirm="deletePatient"
    />
  </div>
</template>
<script>
import PatientInfo from "../components/Forms/PatientInfo";
import Demographic from "../components/Forms/Demographic";
import Visit0 from "../components/Forms/Visit0";
import Visit1 from "../components/Forms/Visit1";
import Visit2 from "../components/Forms/Visit2";
import Visit3 from "../components/Forms/Visit3";
import Visit4 from "../components/Forms/Visit4";
import EndOfTreatment from "../components/Forms/EndofTreatment";
import DeleteConfirmModal from "../components/Modals/deleteConfirmModal";

import {
  Patient,
  Visit_0,
  Visit_1,
  Visit_2,
  Visit_3,
  Visit_4,
} from "../data/index";

import { mapState } from "vuex";
export default {
  name: "patient",
  data() {
    return {
      pageType: "new", // ['new', 'edit']
      patient: Patient(),
      visit_0: Visit_0(),
      visit_1: Visit_1(),
      visit_2: Visit_2(),
      visit_3: Visit_3(),
      visit_4: Visit_4(),
      patientDeleteModal: false,
      lockStatus: false,
      visit0LockStatus: false,
      visit1LockStatus: false,
      visit2LockStatus: false,
      visit3LockStatus: false,
      visit4LockStatus: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    approvement() {
      return (
        this.lockStatus &&
        this.visit0LockStatus &&
        this.visit1LockStatus &&
        this.visit2LockStatus &&
        this.visit3LockStatus &&
        this.visit4LockStatus
      );
    },
  },
  components: {
    PatientInfo,
    Demographic,
    Visit0,
    Visit1,
    Visit2,
    Visit3,
    Visit4,
    DeleteConfirmModal,
    EndOfTreatment
  },
  methods: {
    getPatient() {
      this.$wait.start("fetchPatient");
      this.$store
        .dispatch("patient/FETCH_PATIENT", this.$route.params.patientId)
        .then((patient) => {
          this.visit_0 = this.mapVisit0(patient.visit_0);
          this.visit_1 = this.mapVisit1(patient.visit_1);
          this.visit_2 = this.mapVisit2(patient.visit_2);
          this.visit_3 = this.mapVisit3(patient.visit_3);
          this.visit_4 = this.mapVisit4(patient.visit_4);

          this.patient = patient;

          this.lockStatus = patient.is_locked;
          this.visit0LockStatus = patient.visit_0_is_locked;
          this.visit1LockStatus = patient.visit_1_is_locked;
          this.visit2LockStatus = patient.visit_2_is_locked;
          this.visit3LockStatus = patient.visit_3_is_locked;
          this.visit4LockStatus = patient.visit_4_is_locked;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchPatient");
        });
    },
    save() {
      if (this.pageType === "new") {
        this.add(this.mapPatientData());
      } else {
        this.update(this.mapPatientData());
      }
    },
    add(patient) {
      this.$wait.start("createPatient");
      this.$store
        .dispatch("patient/ADD_PATIENT", patient)
        .then(() => {
          this.showSuccess(this.t("message_patient_added"));
          this.$router.push({ name: "patients" });
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("createPatient");
        });
    },

    update(patient) {
      this.$wait.start("updatePatient");
      this.$store
        .dispatch("patient/UPDATE_PATIENT", patient)
        .then(() => {
          this.showSuccess(this.t("message_patient_updated"));
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("updatePatient");
        });
    },
    mapPatientData() {
      let visit_0 = JSON.parse(JSON.stringify(this.visit_0));
      let visit_1 = JSON.parse(JSON.stringify(this.visit_1));
      let visit_2 = JSON.parse(JSON.stringify(this.visit_2));
      let visit_3 = JSON.parse(JSON.stringify(this.visit_3));
      let visit_4 = JSON.parse(JSON.stringify(this.visit_4));

      return {
        ...JSON.parse(JSON.stringify(this.patient)),
        visit_0: visit_0,
        visit_1: visit_1,
        visit_2: visit_2,
        visit_3: visit_3,
        visit_4: visit_4,
      };
    },
    deletePatient() {
      this.$wait.start("deletePatient");
      this.$store
        .dispatch("patient/DELETE_PATIENT", this.$route.params.patientId)
        .then(() => {
          this.showSuccess(this.t("message_deleted"));
          this.$router.push({ name: "patients" });
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("deletePatient");
        });
    },
    updateLockStatus(newStatus, lock_column) {
      this.$wait.start("lockStatusChange");
      this.$store
        .dispatch("patient/CHANGE_LOCK_STATUS", {
          id: this.$route.params.patientId,
          payload: { is_locked: newStatus, lock_column },
        })
        .then(() => {
          switch (lock_column) {
            case "is_locked":
              this.lockStatus = newStatus;
              break;
            case "visit_0_is_locked":
              this.visit0LockStatus = newStatus;
              break;
            case "visit_1_is_locked":
              this.visit1LockStatus = newStatus;
              break;
            case "visit_2_is_locked":
              this.visit2LockStatus = newStatus;
              break;
            case "visit_3_is_locked":
              this.visit3LockStatus = newStatus;
              break;
            case "visit_4_is_locked":
              this.visit4LockStatus = newStatus;
              break;

            default:
              break;
          }

          this.showSuccess(this.t("message_updated"));
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("lockStatusChange");
        });
    },
  },
  created() {
    if (!this.$route.params.patientId) {
      this.pageType = "new";
    } else {
      this.pageType = "edit";
      this.getPatient();
    }
  },
};
</script>
<style></style>
