<template>
  <div>
    <base-loader
      v-if="
        $wait.is('fetchpatient') ||
        $wait.is('updatePatient') ||
        $wait.is('fetchCenters')
      "
    />
    <base-alert type="danger" v-if="lockStatus">
      <strong>{{ t("warning") }}!</strong> <br />
      {{ t("approvement_message") }}!
    </base-alert>
    <div class="row">
      <div class="col-12 col-md-6">
        <ValidationProvider
          :name="t('patient_birthdate')"
          rules="required"
          v-slot="{ errors }"
        >
          <base-date-input
            :label="t('patient_birthdate')"
            required
            v-model="patient.patient_birthdate"
            :error="errors[0]"
          >
          </base-date-input>
        </ValidationProvider>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-control-label">
            {{ t("gender") }}
          </label>
          <div class="d-flex">
            <base-radio
              dataValue="MALE"
              v-model="patient.gender"
              value="MALE"
              class="pl-4"
            >
              {{ t("male") }}
            </base-radio>
            <base-radio
              dataValue="FEMALE"
              :label="t('gender')"
              v-model="patient.gender"
              value="FEMALE"
            >
              {{ t("female") }}
            </base-radio>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-control-label">
            {{ t("ethnicity") }}
          </label>
          <select class="form-control" v-model="patient.ethnicity">
            <option value="Caucasian">{{ t("Caucasian") }}</option>
            <option value="Latino/Hispanic">{{ t("Latino/Hispanic") }}</option>
            <option value="Middle Eastern">{{ t("Middle Eastern") }}</option>
            <option value="African">{{ t("African") }}</option>
            <option value="Caribbean">{{ t("Caribbean") }}</option>
            <option value="South Asian">{{ t("South Asian") }}</option>
            <option value="East Asian">{{ t("East Asian") }}</option>
            <option value="Other">{{ t("Other") }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-control-label">
            {{ t("education") }}
          </label>
          <select class="form-control" v-model="patient.education">
            <option value="Illiterate">{{ t("Illiterate") }}</option>
            <option value="Elementary school">
              {{ t("Elementary school") }}
            </option>
            <option value="Secondary school">
              {{ t("Secondary school") }}
            </option>
            <option value="High school">{{ t("High school") }}</option>
            <option value="University">{{ t("University") }}</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group mb-2">
          <label class="form-control-label">
            {{ t("occupation") }}
          </label>
          <select class="form-control" v-model="patient.occupation">
            <option value="not working">{{ t("not working") }}</option>
            <option value="student">{{ t("student") }}</option>
            <option value="active worker">{{ t("active worker") }}</option>
            <option value="public officer">{{ t("public officer") }}</option>
            <option value="self-employed">{{ t("self-employed") }}</option>
            <option value="retired">{{ t("retired") }}</option>
            <option value="other">{{ t("Other") }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <base-input
            :label="t('occupation_other')"
            v-model="patient.occupation_other"
            v-show="patient.occupation === 'other'"
          >
          </base-input>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <patient-boolean
          displayBlock
          v-model="patient.alcohol_comsumption"
          :label="t('Alcohol consumption')"
        />
      </div>
      <div class="col-12 col-md-6 border-bottom">
        <label class="form-control-label mt-2">
          {{ t("Smoker Status") }}
        </label>
        <div class="d-flex mb-2">
          <base-radio
            dataValue="0"
            v-model="patient.smoker_status"
            class="mr-4 pl-4"
          >
            {{ t("yes") }}
          </base-radio>
          <base-radio
            dataValue="1"
            v-model="patient.smoker_status"
            class="mr-4"
          >
            {{ t("no") }}
          </base-radio>
          <base-radio
            dataValue="ex-smoker"
            v-model="patient.smoker_status"
            class="mr-4"
          >
            {{ t("Ex-smoker") }}
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PatientBoolean from "./partials/patientBoolean.vue";
export default {
  name: "demographic",
  components: {
    PatientBoolean,
  },
  data() {
    return {};
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
    lockStatus: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {},
};
</script>
