<template>
  <div class="row mt-4">
    <div class="col-12">
      <h2>{{ t("ibs_sss") }}</h2>
    </div>
    <is-completed v-model="value" :label="t('Is IBS-SSS Completed?')" />
    <div class="col-12">
      <patient-boolean
        v-model="value.f_1_a"
        :label="t('sss_f1_a')"
        class="pl-0"
      />
    </div>
    <div class="col-12" v-if="value.f_1_a">
      <paint-percent-slider :label="t('sss_f1_b')" v-model="value.f_1_b" />
    </div>
    <div class="col-12" v-if="value.f_1_a">
      <base-input :label="t('sss_f1_c')" v-model="value.f_1_c">
        <template v-slot:infoBlock>
          <badge type="info">{{ t("sss_f1_c_info") }}</badge>
        </template>
      </base-input>
    </div>
    <patient-boolean v-model="value.f_2_a" :label="t('sss_f2_a')" />
    <div class="col-12 col-md-6" v-if="value.f_2_a">
      <paint-percent-slider :label="t('sss_f2_b')" v-model="value.f_2_b" />
    </div>
    <div class="col-12">
      <paint-percent-slider
        :label="t('sss_f3')"
        :data="sliderDataType1"
        v-model="value.f_3"
      />
    </div>
    <div class="col-12">
      <paint-percent-slider
        :label="t('sss_f4')"
        :data="sliderDataType2"
        v-model="value.f_4"
      />
    </div>
    <div class="col-12 col-md-6">
      <base-input :label="t('sss_f5_a')" v-model="value.f_5_a"> </base-input>
    </div>
    <div class="col-12 col-md-6">
      <base-input :label="t('sss_f5_b')" v-model="value.f_5_b"> </base-input>
    </div>
    <div class="col-12">
      <label class="form-control-label">
        {{ t("sss_f6") }}
      </label>
    </div>
    <div class="col-12 pl-6">
      <div class="d-flex">
        <label class="form-control-label"> a) {{ t("normal") }} </label>
        <base-radio dataValue="0" v-model="value.f_6_normal" class="mb-2">
          {{ t("often") }}
        </base-radio>
        <base-radio dataValue="1" v-model="value.f_6_normal" class="mb-2">
          {{ t("occasionally") }}
        </base-radio>
        <base-radio dataValue="2" v-model="value.f_6_normal" class="mb-2">
          {{ t("never") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 pl-6">
      <div class="d-flex">
        <label class="form-control-label"> b) {{ t("hard") }} </label>
        <base-radio dataValue="0" v-model="value.f_6_hard" class="mb-2">
          {{ t("often") }}
        </base-radio>
        <base-radio dataValue="1" v-model="value.f_6_hard" class="mb-2">
          {{ t("occasionally") }}
        </base-radio>
        <base-radio dataValue="2" v-model="value.f_6_hard" class="mb-2">
          {{ t("never") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 pl-6">
      <div class="d-flex">
        <label class="form-control-label"> c) {{ t("very_thin") }} </label>
        <base-radio dataValue="0" v-model="value.f_6_very_thin" class="mb-2">
          {{ t("often") }}
        </base-radio>
        <base-radio dataValue="1" v-model="value.f_6_very_thin" class="mb-2">
          {{ t("occasionally") }}
        </base-radio>
        <base-radio dataValue="2" v-model="value.f_6_very_thin" class="mb-2">
          {{ t("never") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 pl-6">
      <div class="d-flex">
        <label class="form-control-label"> d) {{ t("small_pieces") }} </label>
        <base-radio dataValue="0" v-model="value.f_6_small_pieces" class="mb-2">
          {{ t("often") }}
        </base-radio>
        <base-radio dataValue="1" v-model="value.f_6_small_pieces" class="mb-2">
          {{ t("occasionally") }}
        </base-radio>
        <base-radio dataValue="2" v-model="value.f_6_small_pieces" class="mb-2">
          {{ t("never") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 pl-6">
      <div class="d-flex">
        <label class="form-control-label"> e) {{ t("mush") }} </label>
        <base-radio dataValue="0" v-model="value.f_6_mush" class="mb-2">
          {{ t("often") }}
        </base-radio>
        <base-radio dataValue="1" v-model="value.f_6_mush" class="mb-2">
          {{ t("occasionally") }}
        </base-radio>
        <base-radio dataValue="2" v-model="value.f_6_mush" class="mb-2">
          {{ t("never") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 pl-6">
      <div class="d-flex">
        <label class="form-control-label"> f) {{ t("watery") }} </label>
        <base-radio dataValue="0" v-model="value.f_6_watery" class="mb-2">
          {{ t("often") }}
        </base-radio>
        <base-radio dataValue="1" v-model="value.f_6_watery" class="mb-2">
          {{ t("occasionally") }}
        </base-radio>
        <base-radio dataValue="2" v-model="value.f_6_watery" class="mb-2">
          {{ t("never") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 mt-2">
      <label class="form-control-label">{{ t("sss_f7") }} </label>
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_7_a"
        :label="t('sss_f7_a')"
        class="pl-0"
      />
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_7_b"
        :label="t('sss_f7_b')"
        class="pl-0"
      />
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_7_c"
        :label="t('sss_f7_c')"
        class="pl-0"
      />
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_7_d"
        :label="t('sss_f7_d')"
        class="pl-0"
      />
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_7_e"
        :label="t('sss_f7_e')"
        class="pl-0"
      />
    </div>
    <body-cross v-model="value.f_7_cross" />
    <div class="col-12 mt-2">
      <label class="form-control-label">{{ t("sss_f8") }} </label>
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_8_a"
        :label="t('sss_f8_a')"
        class="pl-0"
      />
    </div>
    <div class="col-12 pl-6">
      <patient-boolean
        v-model="value.f_8_b"
        :label="t('sss_f8_b')"
        class="pl-0"
      />
    </div>
    <div class="col-12 mt-2">
      <label class="form-control-label">{{ t("sss_f9") }} </label>
    </div>
    <div class="col-12 pl-6">
      <base-input v-model="value.f_9_a" :label="t('sss_f9_a')" class="pl-0" />
    </div>
    <div class="col-12 pl-6">
      <base-input v-model="value.f_9_b" :label="t('sss_f9_b')" class="pl-0" />
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./partials/patientBoolean.vue";
import PaintPercentSlider from "./partials/paintPercentSlider.vue";
import BodyCross from "./partials/bodyCross.vue";
import IsCompleted from "./partials/isCompleted.vue";
export default {
  name: "IbsSss",
  data() {
    return {
      sliderDataType1: [
        {
          value: 0,
          label: "very happy",
        },
        {
          value: 33,
          label: "quite happy",
        },
        {
          value: 66,
          label: "unhappy",
        },
        {
          value: 100,
          label: "very unhappy",
        },
      ],
      sliderDataType2: [
        {
          value: 0,
          label: "not at all",
        },
        {
          value: 33,
          label: "not much",
        },
        {
          value: 66,
          label: "quite a lot",
        },
        {
          value: 100,
          label: "completely",
        },
      ],
    };
  },
  components: {
    PatientBoolean,
    PaintPercentSlider,
    BodyCross,
    IsCompleted,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
