<template>
  <div>
    <base-alert type="danger" v-if="lockStatus">
      <strong>{{ t("warning") }}!</strong> <br />
      {{ t("approvement_message") }}!
    </base-alert>
    <tabs fill class="flex-column flex-md-row">
      <tab-pane :title="t('follow_up_call')">
        <div class="row mt-4">
          <div class="col-12">
            <h2>{{ t("follow_up_call") }}</h2>
          </div>
          <div class="col-12">
            <base-date-input
              :label="t('fuc_date')"
              required
              v-model="visit_2.fuc_date"
            >
            </base-date-input>
          </div>
          <patient-boolean v-model="visit_2.fuc_q1" :label="t('fuc_q1')" />
          <patient-boolean v-model="visit_2.fuc_q2" :label="t('fuc_q2')" />
        </div>
      </tab-pane>
      <tab-pane :title="t('serious_adverse_events')">
        <patient-boolean
          v-model="visit_2.f_3"
          :label="t('visit_2_f_3')"
          classes="my-4"
        />
        <serious-adverse-events
          :serious_adverse_events="visit_2.serious_adverse_events"
          class="mt-4"
          v-show="saeStatus"
        />
      </tab-pane>
      <tab-pane :title="t('tab_drug')">
        <drugs
          :drugs="visit_2.drugs"
          v-model="visit_2.supplement_usage"
          class="mt-4 mb-4"
        />
      </tab-pane>
      <tab-pane :title="t('protocol_deviations')">
        <protocol-deviations v-model="visit_2.protocol_deviations" />
      </tab-pane>
    </tabs>

    <div class="row" v-if="user.level === 'MASTER' || user.level === 'VIEWER'">
      <div class="col-12 mt-4">
        <div class="d-flex align-items-center">
          <label class="form-control-label">
            {{ t("approve_visit_2") }}:
          </label>
          <base-switch
            @input="updateLockStatus($event)"
            class="ml-2"
            :disabled="!patient.id"
            v-model="lock"
          ></base-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import patientBoolean from "./partials/patientBoolean";
import SeriousAdverseEvents from "./SeriousAdverseEvents";
import Drugs from "./Drugs";
import ProtocolDeviations from "./partials/protocolDeviations.vue";
export default {
  name: "visit2",
  data() {
    return {
      lock: false,
    };
  },
  components: {
    patientBoolean,
    SeriousAdverseEvents,
    Drugs,
    ProtocolDeviations,
  },
  props: {
    visit_2: {
      type: Object,
      required: true,
    },
    lockStatus: {
      type: Boolean,
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    saeStatus() {
      return Boolean(this.visit_2.f_3);
    },
  },
  methods: {
    updateLockStatus(val) {
      this.$emit("updateLockStatus", val);
    },
  },
  created() {
    this.lock = this.lockStatus;
  },
};
</script>
