<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-3 text-white">{{ t("account_information") }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">{{ user.full_name }}</h3>
                </div>
              </div>
            </div>
            <template>
              <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="save" class="position-relative">
                  <base-loader
                    v-if="
                      $wait.is('fetchCurrentUser') || $wait.is('updateProfile')
                    "
                  />
                  <base-input
                    :label="t('username')"
                    v-model="user.username"
                    disabled
                  >
                  </base-input>
                  <ValidationProvider
                    :name="t('full_name')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <base-input
                      :label="t('full_name')"
                      required
                      v-model="user.full_name"
                      :error="errors[0]"
                    >
                    </base-input>
                  </ValidationProvider>
                  <ValidationProvider
                    :name="t('password')"
                    rules="min:5"
                    v-slot="{ errors }"
                  >
                    <base-input
                      :label="t('password')"
                      type="password"
                      v-model="user.password"
                      :error="errors[0]"
                    >
                    </base-input>
                  </ValidationProvider>
                  <base-input
                    :label="t('phone')"
                    type="tel"
                    v-model="user.phone"
                  >
                  </base-input>
                  <ValidationProvider
                    :name="t('email')"
                    rules="email"
                    v-slot="{ errors }"
                  >
                    <base-input
                      :label="t('email')"
                      type="email"
                      v-model="user.email"
                      :error="errors[0]"
                    >
                    </base-input>
                  </ValidationProvider>
                  <div class="">
                    <base-button
                      type="success"
                      class="my-4"
                      nativeType="submit"
                      :disabled="invalid"
                    >
                      {{ t("button_save") }}
                    </base-button>
                  </div>
                </form>
              </ValidationObserver>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "user-profile",
  data() {
    return {
      user: {
        username: "",
        password: "",
        full_name: "",
        phone: "",
        email: "",
      },
    };
  },
  methods: {
    getUser() {
      this.$wait.start("fetchCurrentUser");
      this.$store
        .dispatch("auth/FETCH_USER")
        .then(({ data }) => {
          this.user = data;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchCurrentUser");
        });
    },
    save() {
      const payload = {
        full_name: this.user.full_name,
        email: this.user.email,
        phone: this.user.phone,
        password: this.user.password,
      };

      this.$wait.start("updateProfile");
      this.$store
        .dispatch("auth/UPDATE_USER", payload)
        .then(() => {
          this.showSuccess(this.t("message_user_updated"));
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("updateProfile");
        });
    },
  },
  created() {
    this.getUser();
  },
};
</script>
<style></style>
