<template>
  <div class="row mt-4 mb-6">
    <div class="col-12">
      <h2>{{ t("vas_title") }}</h2>
    </div>
    <div class="col-12 mb-2">
      <patient-boolean
        v-model="visit.vas_score_is_completed"
        :label="t('Is VAS-11 Completed?')"
        classes="pl-0"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('note')"
        v-model="visit.vas_score_is_completed_note"
        v-if="
          visit.vas_score_is_completed === false ||
          visit.vas_score_is_completed === 0
        "
      />
    </div>
    <div class="col-12">
      <div class="vas-wrapper">
        <img src="../../../assets/imgs/vas.png" alt="" />
        <vue-slider
          v-model="model"
          :min="0"
          :max="10"
          :marks="true"
          tooltip="always"
          type="number"
        ></vue-slider>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import PatientBoolean from "./patientBoolean.vue";
export default {
  name: "WasScore",
  components: {
    VueSlider,
    PatientBoolean,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    visit: {
      type: Object,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.vas-wrapper {
  width: 494px;
  margin: 0 auto;
}
</style>