<template>
  <div>
    <base-loader
      v-if="
        $wait.is('fetchpatient') ||
        $wait.is('updatePatient') ||
        $wait.is('fetchCenters')
      "
    />
    <base-alert type="danger" v-if="lockStatus">
      <strong>{{ t("warning") }}!</strong> <br />
      {{ t("approvement_message") }}!
    </base-alert>
    <tabs fill class="flex-column flex-md-row">
      <tab-pane :title="t('tab_general')">
        <div class="row my-4">
          <div class="col-12">
            <h2>{{ t("screening") }}</h2>
          </div>
          <div class="col-12 col-md-6">
            <base-date-input
              :label="t('visit_date')"
              v-model="visit_0.visit_date"
            >
            </base-date-input>
          </div>
          <div class="col-12 col-md-6">
            <base-input
              :label="t('screening_number')"
              v-model="visit_0.screening_number"
              :disabled="true"
            >
            </base-input>
          </div>
        </div>
        <appointment v-model="visit_0" />
        <!-- <daily-diary v-model="visit_0" /> -->
        <div class="row my-4">
          <div class="col-12">
            <h2>{{ t("informed_consent") }}</h2>
            <patient-boolean
              classes="px-0"
              :label="t('patient_approve')"
              v-model="patient.patient_approve"
            />
            <div class="row ml-2 mr-2 mt-2" v-if="patient.patient_approve">
              <div class="col-12 col-md-6">
                <base-input
                  :label="t('Version of Informed Consent signed')"
                  v-model="patient.patient_approve_version"
                >
                </base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-date-input
                  :label="t('Date signed')"
                  v-model="patient.patient_approve_date"
                >
                </base-date-input>
              </div>
              <div class="col-12 col-md-6">
                <patient-boolean
                  classes="px-0"
                  :label="
                    t(
                      'Was another version of Informed Consent signed during the study?'
                    )
                  "
                  v-model="patient.patient_approve_version_other"
                />
              </div>
              <div
                class="col-12 col-md-6"
                v-if="patient.patient_approve_version_other"
              >
                <base-date-input
                  :label="t('Other version of Informed Consent – Date signed')"
                  v-model="patient.patient_approve_version_other_value"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-12">
            <h2>{{ t("Medical History") }}</h2>
          </div>
          <patient-boolean
            :label="
              t('Has the subject had any significant diseases or surgeries')
            "
            v-model="visit_0.diseases_or_surgeries"
          />
        </div>
        <medical-histories
          v-model="visit_0.medical_histories"
          v-if="visit_0.diseases_or_surgeries"
        />
        <bss-table v-model="visit_0" />
      </tab-pane>
      <tab-pane :title="t('tab_vas')">
        <vas-score v-model="visit_0.vas_score" :visit="visit_0" />
      </tab-pane>
      <tab-pane :title="t('tab_pevs')">
        <pe-vs v-model="visit_0.pe_vs" />
      </tab-pane>
      <tab-pane :title="t('tab_criterias')">
        <criteria
          v-model="visit_0.criteria"
          class="mt-4"
        />
        <div class="col-12 col-md-6" v-if="criteriasCompleted">
          <div class="form-group">
            <label class="form-control-label">
              {{ t("randomization_randomization_no") }} *
            </label>
            <ValidationProvider
              :name="t('center')"
              :rules="criteriasCompleted ? 'required' : null"
              v-slot="{ errors }"
            >
              <select
                class="form-control"
                v-model="patient.randomization_id"
                :disabled="true"
              >
                <option
                  v-for="(randomization, randomizationKey) in randomizations"
                  :value="randomization.id"
                  :key="randomizationKey"
                >
                  {{ randomization.randomization_no }}
                </option>
              </select>
              <base-alert type="warning" v-if="errors[0]" class="mt-2">
                {{ errors[0] }}
              </base-alert>
            </ValidationProvider>
          </div>
        </div>
      </tab-pane>
      <tab-pane :title="t('tab_rome_4')">
        <div class="row mt-4">
          <div class="col-12">
            <h2>
              {{ t("ROME IV CRITERIA FOR IBS-D") }}
            </h2>
          </div>
          <is-completed
            v-model="visit_0.screening"
            :label="t('Is Rome IV Criteria Questionnaire Completed?')"
          />
          <patient-boolean
            v-model="visit_0.screening.field_21"
            :label="'1. ' + t('field_21')"
          />
          <patient-boolean
            v-model="visit_0.screening.field_22"
            :label="'2. ' + t('field_22')"
          />
          <patient-percent
            v-model="visit_0.screening.field_22_2"
            :label="'3. ' + t('field_22_2')"
          />

          <patient-percent
            v-model="visit_0.screening.field_23"
            :label="'4. ' + t('field_23')"
          />

          <patient-percent
            v-model="visit_0.screening.field_23_2"
            :label="'5. ' + t('field_23_2')"
          />
          <patient-percent
            v-model="visit_0.screening.field_24"
            :label="'6. ' + t('field_24')"
            :na="true"
          />
          <div class="col-12 py-2 border-bottom">
            <div class="row">
              <div class="col-6">
                <label class="form-control-label">
                  7. {{ t("field_25") }}
                </label>
              </div>
              <div class="col-6 d-flex flex-wrap">
                <base-checkbox
                  v-model="visit_0.screening.field_25"
                  class="mr-4"
                  dataValue="UsuallyConstipation"
                >
                  {{ t("UsuallyConstipation") }}
                </base-checkbox>
                <base-checkbox
                  v-model="visit_0.screening.field_25"
                  class="mr-4"
                  dataValue="UsuallDiarrhea"
                >
                  {{ t("UsuallDiarrhea") }}
                </base-checkbox>
                <base-checkbox
                  v-model="visit_0.screening.field_25"
                  class="mr-4"
                  dataValue="BothDiarrheaAndConstipation"
                >
                  {{ t("BothDiarrheaAndConstipation") }}
                </base-checkbox>
                <base-checkbox
                  v-model="visit_0.screening.field_25"
                  class="mr-4"
                  dataValue="NotApplicable"
                >
                  {{ t("NotApplicable") }}
                </base-checkbox>
              </div>
            </div>
          </div>

          <patient-boolean
            v-model="visit_0.screening.field_26"
            :label="'8. ' + t('field_26')"
          />
          <patient-percent
            v-model="visit_0.screening.field_27"
            :label="'9. ' + t('field_27')"
            :na="true"
          />
          <patient-percent
            v-model="visit_0.screening.field_28"
            :label="'10. ' + t('field_28')"
            :na="true"
          />
          <patient-percent
            v-model="visit_0.screening.field_29"
            :label="'11. ' + t('field_29')"
            :na="true"
          />
          <div class="col-12 py-2 border-bottom">
            <div class="row">
              <div class="col-6">
                <label class="form-control-label">
                  12. {{ t("field_30") }}
                </label>
              </div>
              <div class="col-6 d-flex flex-column">
                <base-radio
                  v-model="visit_0.screening.field_30"
                  class="mr-4"
                  dataValue="Abdominal pain"
                >
                  {{ t("Abdominal pain") }}
                </base-radio>
                <base-radio
                  v-model="visit_0.screening.field_30"
                  class="mr-4"
                  dataValue="Watery or mushy stools, or having many bowel movements in a day"
                >
                  {{
                    t(
                      "Watery or mushy stools, or having many bowel movements in a day"
                    )
                  }}
                </base-radio>
                <base-radio
                  v-model="visit_0.screening.field_30"
                  class="mr-4"
                  dataValue="Hard stools or going several days without having a bowel movement"
                >
                  {{
                    t(
                      "Hard stools or going several days without having a bowel movement"
                    )
                  }}
                </base-radio>
                <base-radio
                  v-model="visit_0.screening.field_30"
                  class="mr-4"
                  dataValue="Bloating or your belly looking unusually large"
                >
                  {{ t("Bloating or your belly looking unusually large") }}
                </base-radio>
                <base-radio
                  v-model="visit_0.screening.field_30"
                  class="mr-4"
                  dataValue="None of the above, but another symptom"
                >
                  {{ t("None of the above, but another symptom") }}
                </base-radio>
                <base-input
                  :label="t('field_30_1')"
                  v-model="visit_0.screening.field_30_1"
                  class="ml-4"
                  v-if="
                    visit_0.screening.field_30 ===
                    'None of the above, but another symptom'
                  "
                >
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </tab-pane>
      <tab-pane :title="t('tab_lab')">
        <div class="row">
          <div class="col-12">
            <lab :lab="visit_0.lab" />
          </div>
        </div>
      </tab-pane>
      <tab-pane :title="t('tab_drug')">
        <drugs :drugs="visit_0.drugs" v-model="visit_0.supplement_usage" />
      </tab-pane>
    </tabs>
    <div class="row" v-if="user.level === 'MASTER' || user.level === 'VIEWER'">
      <div class="col-12 mt-4">
        <div class="d-flex align-items-center">
          <label class="form-control-label">
            {{ t("approve_visit_0") }}:
          </label>
          <base-switch
            @input="updateLockStatus($event)"
            class="ml-2"
            :disabled="!patient.id"
            v-model="lock"
          ></base-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import patientBoolean from "./partials/patientBoolean";
import patientPercent from "./partials/patientPercent";
import Criteria from "./Criteria.vue";
import Lab from "./Lab";
import Appointment from "./partials/appointment.vue";
// import DailyDiary from "./partials/dailyDiary.vue";
import PatientBoolean from "./partials/patientBoolean.vue";
import PeVs from "./PeVs.vue";
import VasScore from "./partials/vasScore.vue";
import Drugs from "./Drugs";
import BssTable from "./partials/bssTable.vue";
import MedicalHistories from "./MedicalHistories.vue";
import IsCompleted from "./partials/isCompleted.vue";

export default {
  name: "visit0",
  data() {
    return {
      lock: false,
    };
  },
  components: {
    patientBoolean,
    patientPercent,
    Lab,
    Criteria,
    Appointment,
    // DailyDiary,
    PatientBoolean,
    PeVs,
    VasScore,
    Drugs,
    BssTable,
    MedicalHistories,
    IsCompleted,
  },
  props: {
    visit_0: {
      type: Object,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
    lockStatus: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      randomizations: (state) => state.randomization.centerRandomizationNumbers,
    }),
    // randomizationExist() {
    //   return (
    //     !!this.patient.randomization &&
    //     !!this.patient.randomization.randomization_no
    //   );
    // },
    criteriasCompleted() {
      return (
        [
          this.visit_0.criteria.field_1,
          this.visit_0.criteria.field_2,
          this.visit_0.criteria.field_3,
          this.visit_0.criteria.field_3_1,
          this.visit_0.criteria.field_3_2,
          this.visit_0.criteria.field_3_3,
          this.visit_0.criteria.field_3_4,
          this.visit_0.criteria.field_4,
          this.visit_0.criteria.field_5,
          this.visit_0.criteria.field_6,
          this.visit_0.criteria.field_7,
          this.visit_0.criteria.field_8,
           this.visit_0.criteria.field_9,
          this.visit_0.criteria.field_10,
          this.visit_0.criteria.field_11,
          this.visit_0.criteria.field_12,
          this.visit_0.criteria.field_13,
          this.visit_0.criteria.field_14,
          this.visit_0.criteria.field_15,
          this.visit_0.criteria.field_16,
          this.visit_0.criteria.field_17,
          this.visit_0.criteria.field_18,
          this.visit_0.criteria.field_19,
          this.visit_0.criteria.field_20,
          this.visit_0.criteria.field_100,
          this.visit_0.criteria.field_101,
          this.visit_0.criteria.field_102,
        ].every((f) => typeof f === 'boolean') 
      );
    },
  },
  watch: {
    criteriasCompleted(status) {
      if (status && this.patient.randomization_id === null &&
        this.randomizations.length > 0) {
        this.patient.randomization_id = this.randomizations[0].id;
      } 
    },
  },
  methods: {
    updateLockStatus(val) {
      this.$emit("updateLockStatus", val);
    },
  },
  created() {
    this.lock = this.lockStatus;
  },
};
</script>
