<template>
  <div class="row my-4">
    <div class="col-12">
      <h2>{{ t("Perceived Stress Scale (PSS) ") }}</h2>
    </div>
    <is-completed v-model="pss" :label="t('Is PSS Completed?')" />

    <base-alert type="primary" class="my-2">
      Please mark the closest reply that suits you for the past month. In each
      case, you will be asked to indicate by circling how often you felt or
      thought a certain way <br />
      <b
        >0 = Never 1 = Almost Never 2 = Sometimes 3 = Fairly Often 4 = Very
        Often</b
      >
    </base-alert>
    <pss-frequency-question v-model="pss.f_1" :label="'1. ' + t('pss_f_1')" />
    <pss-frequency-question v-model="pss.f_2" :label="'2. ' + t('pss_f_2')" />
    <pss-frequency-question v-model="pss.f_3" :label="'3. ' + t('pss_f_3')" />
    <pss-frequency-question v-model="pss.f_4" :label="'4. ' + t('pss_f_4')" />
    <pss-frequency-question v-model="pss.f_5" :label="'5. ' + t('pss_f_5')" />
    <pss-frequency-question v-model="pss.f_6" :label="'6. ' + t('pss_f_6')" />
    <pss-frequency-question v-model="pss.f_7" :label="'7. ' + t('pss_f_7')" />
    <pss-frequency-question v-model="pss.f_8" :label="'8. ' + t('pss_f_8')" />
    <pss-frequency-question v-model="pss.f_9" :label="'9. ' + t('pss_f_9')" />
    <pss-frequency-question
      v-model="pss.f_10"
      :label="'10. ' + t('pss_f_10')"
    />
  </div>
</template>
<script>
import PssFrequencyQuestion from "./partials/PssFrequencyQuestion";
import IsCompleted from "./partials/isCompleted.vue";
import { mapState } from "vuex";
export default {
  name: "pss",
  data() {
    return {};
  },
  components: {
    PssFrequencyQuestion,
    IsCompleted,
  },
  props: {
    pss: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {},
  created() {},
};
</script>
