<template>
  <div class="col-12 py-2 border-bottom">
    <div class="row">
      <div class="col-6">
        <label class="form-control-label">
          {{ label }}
        </label>
      </div>
      <div class="col-6">
        <div class="d-flex flex-wrap">
          <base-radio dataValue="0" v-model="model" class="mr-4">
            {{ t("0%") }}
          </base-radio>
          <base-radio dataValue="10" v-model="model" class="mr-4">
            {{ t("10%") }}
          </base-radio>
          <base-radio dataValue="20" v-model="model" class="mr-4">
            {{ t("20%") }}
          </base-radio>
          <base-radio dataValue="30" v-model="model" class="mr-4">
            {{ t("30%") }}
          </base-radio>
          <base-radio dataValue="40" v-model="model" class="mr-4">
            {{ t("40%") }}
          </base-radio>
          <base-radio dataValue="50" v-model="model" class="mr-4">
            {{ t("50%") }}
          </base-radio>
          <base-radio dataValue="60" v-model="model" class="mr-4">
            {{ t("60%") }}
          </base-radio>
          <base-radio dataValue="70" v-model="model" class="mr-4">
            {{ t("70%") }}
          </base-radio>
          <base-radio dataValue="80" v-model="model" class="mr-4">
            {{ t("80%") }}
          </base-radio>
          <base-radio dataValue="90" v-model="model" class="mr-4">
            {{ t("90%") }}
          </base-radio>
          <base-radio dataValue="100" v-model="model" class="mr-4">
            {{ t("100%") }}
          </base-radio>
          <base-radio dataValue="N/A" v-model="model" class="mr-4" v-if="na">
            {{ t("This question does not apply to me") }}
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "patient-percent",
  props: {
    label: {
      type: [String, Number],
      description: "Radio label",
    },
    value: {
      type: [String, Boolean],
      description: "Radio value",
    },
    na: {
      type: Boolean,
      description: "This question does not apply to me value",
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.form-control-label {
  margin-bottom: 0;
}
</style>
