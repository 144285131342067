<template>
  <div class="row my-4">
    <div class="col-12">
      <h2>{{ t("serious_adverse_events") }}</h2>
    </div>
    <div class="col-12">
      <table class="table tablesorter table align-items-center table-flush">
        <thead>
          <tr>
            <th>{{ t("Intensity") }}</th>
            <th>{{ t("Causal Relationship with Study Drug ") }}</th>
            <th>{{ t("Precaution(s) Taken About the Study Drug ") }}</th>
            <th>{{ t("Has treatment been required?") }}</th>
          </tr>
        </thead>
        <single-sae
          v-for="(sae, keySae) in serious_adverse_events"
          :key="keySae"
          :sae="sae"
        />
      </table>
      <base-button
        outline
        type="primary"
        icon="ni ni-fat-add"
        @click="addSae()"
        class="mt-2"
      >
        {{ t("button_add") }}
      </base-button>
    </div>
  </div>
</template>
<script>
import SingleSae from "./SingleSae";
import { mapState } from "vuex";
export default {
  name: "serious-adverse-events",
  data() {
    return {};
  },
  components: {
    SingleSae,
  },
  props: {
    serious_adverse_events: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    addSae() {
      this.serious_adverse_events.push({
        name: null,
        initiation_date: null,
        recovery_date: null,
        intensity: null,
        casual_relationship_studty_drug: null,
        precaution_studty_drug: null,
        required: null,
      });
    },
  },
  created() {
    if (this.serious_adverse_events.length === 0) this.addSae();
  },
};
</script>
