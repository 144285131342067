<template>
  <div class="col-12 py-2 border-bottom">
    <div class="row">
      <div class="col-5">
        <label class="form-control-label">
          {{ label }}
        </label>
      </div>
      <div class="col-7">
        <div class="d-flex flex-wrap">
          <base-radio dataValue="0" v-model="model" class="mr-4">
            0
          </base-radio>
          <base-radio dataValue="1" v-model="model" class="mr-4">
            1
          </base-radio>
          <base-radio dataValue="2" v-model="model" class="mr-4">
            2
          </base-radio>
          <base-radio dataValue="3" v-model="model" class="mr-4">
            3
          </base-radio>
          <base-radio dataValue="4" v-model="model" class="mr-4">
            4
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pss-frequency-question",
  props: {
    label: {
      type: [String, Number],
      description: "Radio label",
    },
    value: {
      type: [String, Number],
      description: "Radio value",
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.form-control-label {
  margin-bottom: 0;
}
</style>
