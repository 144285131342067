<template>
  <div class="col-12 py-2 border-bottom">
    <div class="row">
      <div class="col-6">
        <label class="form-control-label">
          {{ label }}
        </label>
      </div>
      <div class="col-6">
        <div class="d-flex flex-wrap">
          <base-radio dataValue="Not at all" v-model="model" class="mr-4">
            {{ t("Not at all") }}
          </base-radio>
          <base-radio dataValue="Slightly" v-model="model" class="mr-4">
            {{ t("Slightly") }}
          </base-radio>
          <base-radio dataValue="Moderately" v-model="model" class="mr-4">
            {{ t("Moderately") }}
          </base-radio>
          <base-radio dataValue="Quite a bit" v-model="model" class="mr-4">
            {{ t("Quite a bit") }}
          </base-radio>
          <base-radio
            dataValue="Extremely"
            v-model="model"
            class="mr-4"
            v-if="extremlyStatus"
          >
            {{ t("Extremely") }}
          </base-radio>
          <base-radio
            dataValue="A great deal"
            v-model="model"
            class="mr-4"
            v-else
          >
            {{ t("A great deal") }}
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "questionnaire-question",
  props: {
    label: {
      type: [String, Number],
      description: "Radio label",
    },
    value: {
      type: [String, Boolean],
      description: "Radio value",
    },
    extremlyStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.form-control-label {
  margin-bottom: 0;
}
</style>
