<template>
  <div class="row appointment my-4">
    <div class="col-12">
      <h2>{{ t("bss_table") }}</h2>
    </div>
    <div class="col-12 mb-2">
      <patient-boolean
        v-model="value.bss_is_completed"
        :label="t('Is BSS Completed?')"
        classes="pl-0"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('note')"
        v-model="value.bss_is_completed_note"
        v-if="value.bss_is_completed === false || value.bss_is_completed === 0"
      />
    </div>
    <div class="col-12 col-md-8">
      <img
        src="../../../assets/imgs/bss-table.png"
        alt="BSS Table"
        title="BSS Table"
      />
      <p class="pt-4">*Patient with Type 1 and Type 2 will not be enrolled</p>
    </div>
    <div class="col-12 col-md-4">
      <base-radio dataValue="type 1" v-model="value.bss_table" class="my-4">
        {{ t("Type  1") }}
      </base-radio>
      <base-radio dataValue="type 2" v-model="value.bss_table" class="my-4">
        {{ t("Type  2") }}
      </base-radio>
      <base-radio dataValue="type 3" v-model="value.bss_table" class="my-4">
        {{ t("Type  3") }}
      </base-radio>
      <base-radio dataValue="type 4" v-model="value.bss_table" class="my-4">
        {{ t("Type  4") }}
      </base-radio>
      <base-radio dataValue="type 5" v-model="value.bss_table" class="my-4">
        {{ t("Type  5") }}
      </base-radio>
      <base-radio dataValue="type 6" v-model="value.bss_table" class="my-4">
        {{ t("Type  6") }}
      </base-radio>
      <base-radio dataValue="type 7" v-model="value.bss_table" class="my-4">
        {{ t("Type  7") }}
      </base-radio>
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./patientBoolean.vue";

export default {
  name: "bssTable",
  components: {
    PatientBoolean,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
