<template>
  <tr>
    <td>
      <base-input :label="t('Name of Drug')" v-model="drug.name"> </base-input>
    </td>
    <td>
      <base-date-input
        :label="t('Initiation Date')"
        v-model="drug.initiation_date"
      >
      </base-date-input>
    </td>
    <td>
      <base-date-input
        :label="t('Withdrawal Date')"
        v-model="drug.withdrawal_date"
      >
      </base-date-input>
    </td>
    <td>
      <base-input inputType="number" :label="t('Dose')" v-model="drug.dose">
      </base-input>
    </td>
    <td>
      <base-input
        :label="t('Indication/Concomitant Disease')"
        v-model="drug.disease"
      >
      </base-input>
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "single-drug",
  data() {
    return {};
  },
  components: {},
  props: {
    drug: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {},
  created() {},
};
</script>
