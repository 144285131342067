<template>
  <div>
    <base-loader
      v-if="
        $wait.is('fetchpatient') ||
        $wait.is('updatePatient') ||
        $wait.is('fetchCenters')
      "
    />
    <base-alert type="danger" v-if="lockStatus">
      <strong>{{ t("warning") }}!</strong> <br />
      {{ t("approvement_message") }}!
    </base-alert>

    <div class="row">
      <div class="col-12 col-md-4">
        <ValidationProvider
          :name="t('patient_initial_1')"
          rules="required"
          v-slot="{ errors }"
        >
          <base-input
            :label="t('patient_initial_1')"
            required
            v-model="patient.patient_initial_1"
            :error="errors[0]"
            :maxlength="1"
          >
          </base-input>
        </ValidationProvider>
      </div>
      <div class="col-12 col-md-4">
        <ValidationProvider :name="t('patient_initial_2')" v-slot="{ errors }">
          <base-input
            :label="t('patient_initial_2')"
            v-model="patient.patient_initial_2"
            :error="errors[0]"
            :maxlength="1"
          >
          </base-input>
        </ValidationProvider>
      </div>
      <div class="col-12 col-md-4">
        <ValidationProvider
          :name="t('patient_initial_3')"
          rules="required"
          v-slot="{ errors }"
        >
          <base-input
            :label="t('patient_initial_3')"
            required
            v-model="patient.patient_initial_3"
            :error="errors[0]"
            :maxlength="1"
          >
          </base-input>
        </ValidationProvider>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-control-label"> {{ t("center") }} * </label>
          <ValidationProvider
            :name="t('center')"
            rules="required"
            v-slot="{ errors }"
          >
            <select
              class="form-control"
              @change="setRandomizations()"
              v-model="patient.center_id"
              :disabled="
                typeof patient.id === 'number' || patient.randomization_id
              "
            >
              <option
                :value="center.id"
                v-for="(center, centerKey) in centers"
                :key="centerKey"
              >
                {{ center.name }}
              </option>
            </select>
            <base-alert type="warning" v-if="errors[0]" class="mt-2">
              {{ errors[0] }}
            </base-alert>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <ValidationProvider
          :name="t('date')"
          rules="required"
          v-slot="{ errors }"
        >
          <base-date-input
            :label="t('date')"
            required
            v-model="patient.date"
            :error="errors[0]"
          >
          </base-date-input>
        </ValidationProvider>
      </div>
      <div class="col-12 col-md-6" v-if="patient.id">
        <ValidationProvider
          :name="t('patient_no')"
          rules="required"
          v-slot="{ errors }"
        >
          <base-input
            :label="t('patient_no')"
            required
            :error="errors[0]"
            disabled
            v-model="patient.patient_no"
          >
          </base-input>
        </ValidationProvider>
      </div>
      <div
        class="col-12 mt-6"
        v-if="user.level === 'MASTER' || user.level === 'VIEWER'"
      >
        <div class="d-flex align-items-center">
          <label class="form-control-label">
            {{ t("approve_patient_information") }}:
          </label>
          <base-switch
            @input="updateLockStatus($event)"
            class="ml-2"
            :disabled="!patient.id"
            v-model="lock"
          ></base-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "patient-info",
  components: {},
  data() {
    return {
      centers: [],
      lock: false,
    };
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
    lockStatus: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getCenters() {
      this.$wait.start("fetchCenters");
      this.$store
        .dispatch("center/FETCH_CENTERS")
        .then((centers) => {
          this.centers = centers;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchCenters");
        });
    },
    setRandomizations() {
      this.$wait.start("fetchRandomizations");
      this.$store
        .dispatch(
          "randomization/FETCH_CENTER_RANDOMIZATION",
          this.patient.center_id
        )
        .then((data) => {
          if (this.patient.id && this.patient.randomization_id) {
            this.$store.dispatch("randomization/ADD_RANDOMIZATION", {
              id: this.patient.randomization.id,
              randomization_no: this.patient.randomization.randomization_no,
            });
          } else {
            this.patient.randomization_id = null;
          }
          if (data.length === 0) {
            this.showError({
              message:
                "There are no available randomization number for this site",
            });
          }
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchRandomizations");
        });
    },
    updateLockStatus(val) {
      this.$emit("updateLockStatus", val);
    },
  },
  created() {
    this.lock = this.lockStatus;
    this.getCenters();
    if (this.patient.id) {
      this.setRandomizations();
    }
  },
};
</script>
