<template>
  <div>
    <base-alert type="danger" v-if="lockStatus">
      <strong>{{ t("warning") }}!</strong> <br />
      {{ t("approvement_message") }}!
    </base-alert>
    <tabs fill class="flex-column flex-md-row">
      <tab-pane :title="t('tab_general')">
        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <base-date-input
              :label="t('Visit Date')"
              v-model="visit_4.visit_date"
            >
            </base-date-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input
              :label="t('blood_pressure')"
              v-model="visit_4.blood_pressure"
            >
            </base-input>
          </div>
          <div class="col-12 col-md-4">
            <base-input
              :label="t('pulse_beats_min')"
              v-model="visit_4.pulse_beats_min"
            >
            </base-input>
          </div>
        </div>
        <appointment v-model="visit_4" />
        <daily-diary :visit="4" v-model="visit_4" />
      </tab-pane>
      <tab-pane :title="t('ibs_sss')">
        <ibs-sss v-model="visit_4.ibs_sss" />
      </tab-pane>
      <tab-pane :title="t('tab_pevs')">
        <pe-vs v-model="visit_4.pe_vs" />
      </tab-pane>
      <tab-pane :title="t('tab_drug')">
        <drugs :drugs="visit_4.drugs" v-model="visit_4.supplement_usage" />
      </tab-pane>
      <tab-pane :title="t('serious_adverse_events')">
        <patient-boolean
          :label="t('serious_adverse_events_status')"
          v-model="visit_4.serious_adverse_events_status"
          classes="my-4"
        />
        <serious-adverse-events
          :serious_adverse_events="visit_4.serious_adverse_events"
          class="mt-4"
          v-show="saeStatus"
        />
      </tab-pane>
      <tab-pane :title="t('tab_pss')">
        <pss :pss="visit_4.pss" class="mt-4" />
      </tab-pane>
      <tab-pane :title="t('tab_qol')">
        <questionnaire :questionnaire="visit_4.questionnaire" class="mt-4" />
      </tab-pane>
      <tab-pane :title="t('protocol_deviations')">
        <protocol-deviations v-model="visit_4.protocol_deviations" />
      </tab-pane>
      <tab-pane :title="t('tab_hads')">
        <hads :hads="visit_4.hads" class="mt-4" />
      </tab-pane>
       <tab-pane :title="t('daily_diaries')">
        <daily-diaries v-model="visit_4.daily_diaries" class="mt-4 mb-4" />
      </tab-pane>
    </tabs>

    <div class="row" v-if="user.level === 'MASTER' || user.level === 'VIEWER'">
      <div class="col-12 mt-4">
        <div class="d-flex align-items-center">
          <label class="form-control-label">
            {{ t("approve_visit_4") }}:
          </label>
          <base-switch
            @input="updateLockStatus($event)"
            class="ml-2"
            :disabled="!patient.id"
            v-model="lock"
          ></base-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SeriousAdverseEvents from "./SeriousAdverseEvents";
import patientBoolean from "./partials/patientBoolean";
import Drugs from "./Drugs";
import Appointment from "./partials/appointment.vue";
import DailyDiary from "./partials/dailyDiary.vue";
import PeVs from "./PeVs.vue";
import Pss from "./Pss.vue";
import IbsSss from "./IbsSss.vue";
import Questionnaire from "./Questionnaire";
import ProtocolDeviations from "./partials/protocolDeviations.vue";
import Hads from "./Hads";
import DailyDiaries from "./partials/dailyDiaries/index.vue";
export default {
  name: "visit4",
  data() {
    return {
      lock: false,
    };
  },
  components: {
    SeriousAdverseEvents,
    patientBoolean,
    Drugs,
    Appointment,
    DailyDiary,
    PeVs,
    Pss,
    IbsSss,
    Questionnaire,
    ProtocolDeviations,
    Hads,
    DailyDiaries
  },
  props: {
    visit_4: {
      type: Object,
      required: true,
    },
    lockStatus: {
      type: Boolean,
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    saeStatus() {
      return Boolean(this.visit_4.serious_adverse_events_status);
    },
  },
  methods: {
    updateLockStatus(val) {
      this.$emit("updateLockStatus", val);
    },
  },
  created() {
    this.lock = this.lockStatus;
  },
};
</script>
