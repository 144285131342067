<template>
  <div class="col-12 py-2 border-bottom">
    <label class="form-control-label">
      {{ label }}
    </label>
    <div class="d-flex mt-2">
      <base-radio dataValue="normal" v-model="model" class="mr-4 pl-4">
        {{ t("normal") }}
      </base-radio>
      <base-radio dataValue="abnormal" v-model="model" class="mr-4">
        {{ t("abnormal") }}
      </base-radio>
      <base-radio dataValue="not_done" v-model="model" class="mr-4">
        {{ t("not_done") }}
      </base-radio>
    </div>
  </div>
</template>
<script>
export default {
  name: "patient-boolean",
  props: {
    label: {
      type: [String, Number],
      description: "Radio label",
    },
    value: {
      type: [String, Boolean, Number],
      description: "Radio value",
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.form-control-label {
  margin-bottom: 0;
}
</style>
