<template>
  <tbody class="list">
    <tr>
      <td>
        <base-input v-model="pa.no" :label="t('pa_no')" class="pl-0" />
      </td>
      <td>
        <base-input v-model="pa.dose" :label="t('pa_dose')" class="pl-0" />
      </td>
      <td>
        <base-date-input
          v-model="pa.date_dispersion"
          :label="t('pa_date_of_dispersion')"
        />
      </td>
      <td>
        <base-input
          v-model="pa.amount_dispersion"
          :label="t('pa_dispersed_amount')"
          class="pl-0"
        />
      </td>
      <td>
        <base-date-input
          v-model="pa.date_return"
          :label="t('pa_date_of_return')"
        />
      </td>
      <td>
        <base-input
          v-model="pa.amount_return"
          :label="t('pa_amount_returned')"
          class="pl-0"
        />
      </td>
      <td>
        <base-button
          outline
          type="danger"
          icon="ni ni-fat-remove"
          size="sm"
          @click="remove()"
        >
        </base-button>
      </td>
    </tr>
  </tbody>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "single-sae",
  data() {
    return {};
  },
  components: {},
  props: {
    pa: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
  },
  created() {},
};
</script>
