<template>
  <div :class="['col-12 py-2 border-bottom', classes]">
    <div :class="[{ 'd-flex justify-content-between': !displayBlock }]">
      <label class="form-control-label">
        {{ label }}
      </label>
      <div :class="['d-flex', { 'mt-2 mb-2': displayBlock }]">
        <base-radio
          :dataValue="true"
          :disabled="disabled"
          v-model="model"
          :class="[{ 'pl-4': displayBlock }]"
        >
          {{ t("yes") }}
        </base-radio>
        <base-radio
          :dataValue="false"
          v-model="model"
          class="ml-2"
          :disabled="disabled"
        >
          {{ t("no") }}
        </base-radio>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "patient-boolean",
  props: {
    label: {
      type: [String, Number],
      description: "Radio label",
    },
    value: {
      type: [String, Boolean, Number],
      description: "Radio value",
    },
    displayBlock: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cbId: "",
    };
  },
  computed: {
    model: {
      get() {
        return this.value === null ? null : Boolean(this.value);
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.form-control-label {
  margin-bottom: 0;
}
</style>
