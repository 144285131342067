<template>
  <div class="mt-4">
    <div
      class="row"
      v-for="(protocolDeviation, keyPd) in value"
      :key="keyPd"
    >
    <div class="col-12">
      <h2>{{ t("protocol_deviations") }}</h2>
    </div>
      <div class="col-11">
        <div class="row">
          <div class="col-12 col-md-6">
            <base-date-input :label="t('date')" v-model="value[keyPd].date" />
          </div>
          <div class="col-12 col-md-6">
            <base-input
              :label="t('site_deviation_number')"
              v-model="value[keyPd].site_deviation_number"
            />
          </div>
          <div class="col-12 col-md-6">
            <base-input
              :label="t('protocol_deviation')"
              v-model="value[keyPd].protocol_deviation"
            />
          </div>
          <div class="col-12 col-md-6">
            <base-input
              :label="t('reason_for_deviation')"
              v-model="value[keyPd].reason_for_deviation"
            />
          </div>
        </div>
      </div>
      <div class="col-1">
        <base-button
          outline
          type="warning"
          icon="ni ni-fat-remove"
          @click="removePd(keyPd)"
        >
        </base-button>
      </div>
    </div>
    <base-button
      outline
      type="primary"
      icon="ni ni-fat-add"
      @click="addPd()"
      class="mb-4"
    >
      {{ t("add_new") }}
    </base-button>
  </div>
</template>
<script>
export default {
  name: "protocolDeviations",
  components: {},
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  methods: {
    removePd(key) {
      this.value.splice(key, 1);
    },
    addPd() {
      this.value.push({
        date: null,
        site_deviation_number: null,
        protocol_deviation: null,
        reason_for_deviation: null,
      });
    },
  },
};
</script>
