<template>
  <div class="row my-4">
    <div class="col-12">
      <h2>{{ t("pe_vs") }}</h2>
    </div>
    <div class="col-12">
      <patient-boolean
        v-model="value.pe_vs_was"
        :label="t('was')"
        class="pl-0"
      />
    </div>
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-6">
          <base-date-input :label="t('date')" v-model="value.pe_vs_date">
          </base-date-input>
        </div>
        <div class="col-6">
          <label class="form-control-label">
            {{ t("time") }}
          </label>
          <input
            class="form-control"
            type="text"
            :label="t('time')"
            v-model="value.pe_vs_time"
            v-mask="'##:##'"
            placeholder="00:00"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <base-input
        type="number"
        :label="t('height')"
        v-model="value.pe_vs_height"
      >
      </base-input>
    </div>
    <div class="col-12 col-md-6">
      <base-input
        type="number"
        :label="t('weight')"
        v-model="value.pe_vs_weight"
      >
      </base-input>
    </div>
    <div class="col-12 col-md-6">
      <base-input type="number" :label="t('bmi')" v-model="value.pe_vs_bmi">
      </base-input>
    </div>
    <div class="col-12 col-md-6">
      <base-input type="number" :label="t('hr')" v-model="value.pe_vs_hr">
      </base-input>
    </div>
    <div class="col-12 col-md-6">
      <base-input type="number" :label="t('bp')" v-model="value.pe_vs_bp">
      </base-input>
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select :label="t('ga')" v-model="value.pe_vs_ga" />
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select :label="t('neck')" v-model="value.pe_vs_neck">
      </pe-vs-select>
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select
        :label="t('chest_and_lungs')"
        v-model="value.pe_vs_chest_and_lings"
      >
      </pe-vs-select>
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select :label="t('abdomen')" v-model="value.pe_vs_abdomen">
      </pe-vs-select>
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select :label="t('lymph_nodes')" v-model="value.pe_vs_lymph_nodes">
      </pe-vs-select>
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select :label="t('extremities_skin')" v-model="value.pe_vs_es">
      </pe-vs-select>
    </div>
    <div class="col-12 col-md-6">
      <pe-vs-select :label="t('other')" v-model="value.pe_vs_other">
      </pe-vs-select>
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./partials/patientBoolean.vue";
import PeVsSelect from "./partials/PeVsSelect.vue";
export default {
  name: "PeVs",
  data() {
    return {};
  },
  components: {
    PatientBoolean,
    PeVsSelect,
  },
  watch: {
    "value.pe_vs_height": function () {
      this.bmiCalculator();
    },
    "value.pe_vs_weight": function () {
      this.bmiCalculator();
    },
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bmiCalculator() {
      if (!this.value.pe_vs_height || !this.value.pe_vs_weight) return;
      const height = parseInt(this.value.pe_vs_height) / 100;
      const weight = parseInt(this.value.pe_vs_weight);
      this.value.pe_vs_bmi = Math.round(weight / (height * height));
    },
  },
};
</script>
