<template>
  <div class="row my-2">
    <div class="col-12">
      <h2>{{ t("eligibility") }}</h2>
    </div>
    <div class="col-12 col-md-6">
      <patient-boolean
        :label="t('eligibility_criteria')"
        classes="pl-0"
        v-model="value.eligibility_criteria"
      />
    </div>
    <div class="col-12 col-md-6">
      <patient-boolean
        :label="t('exemption_granted')"
        classes="pl-0"
        v-model="value.exemption_granted"
      />
    </div>
    <div class="col-12 col-md-6 mt-2">
      <base-input
        :label="t('exemption_reason')"
        v-model="value.exemption_reason"
      />
    </div>
    <div class="col-12 col-md-6 mt-2">
      <label class="form-control-label">{{ t("subject_failed_visit") }}</label>
      <div class="d-flex">
        <base-radio
          dataValue="0"
          v-model="value.subject_failed_visit"
          value="0"
        >
          {{ t("Visit 0") }}
        </base-radio>
        <base-radio
          dataValue="1"
          v-model="value.subject_failed_visit"
          value="1"
        >
          {{ t("Visit 1") }}
        </base-radio>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <base-input
        :label="t('primary_reason_for_non_eligibility')"
        v-model="value.primary_reason_for_non_eligibility"
      />
    </div>
    <div class="col-12 col-md-6">
      <base-date-input :label="t('visit_2_date')" v-model="value.visit_2_date">
      </base-date-input>
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./patientBoolean.vue";

export default {
  name: "appointment",
  components: {
    PatientBoolean,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
