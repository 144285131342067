<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent">
          <div class="text-center">
            <img src="img/croturk.png" alt="CROTURK" class="my-4" />
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="login" class="position-relative">
              <base-loader v-if="$wait.is('login')" />
              <ValidationProvider
                :name="t('username')"
                rules="required"
                v-slot="{ errors }"
              >
                <base-input
                  class="input-group-alternative"
                  :placeholder="t('username')"
                  addon-left-icon="ni ni-single-02"
                  v-model="user.username"
                  :error="errors[0]"
                >
                </base-input>
              </ValidationProvider>
              <ValidationProvider
                :name="t('password')"
                rules="required"
                v-slot="{ errors }"
              >
                <base-input
                  class="input-group-alternative mt-3"
                  :placeholder="t('password')"
                  inputType="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="user.password"
                  :error="errors[0]"
                >
                </base-input>
              </ValidationProvider>
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4"
                  nativeType="submit"
                  :disabled="invalid"
                >
                  {{ t("button_login") }}
                </base-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
    };
  },
  computed: {},
  methods: {
    login() {
      this.$wait.start("login");
      this.$store
        .dispatch("auth/AUTH_REQUEST", this.user)
        .then(() => {
          this.$router.push({ name: "patients" });
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            title: "Giriş Bilgileri Hatalı",
          });
        })
        .finally(() => {
          this.$wait.end("login");
        });
    },
  },
};
</script>
<style></style>
