<template>
  <div>
    <base-alert type="danger" v-if="lockStatus">
      <strong>{{ t("warning") }}!</strong> <br />
      {{ t("approvement_message") }}!
    </base-alert>
    <tabs fill class="flex-column flex-md-row">
      <tab-pane :title="t('tab_general')">
        <div class="row my-4">
          <div class="col-12">
            <base-date-input
              :label="t('visit_date')"
              v-model="visit_1.visit_date"
            >
            </base-date-input>
          </div>
          <patient-boolean
            displayBlock
            v-model="visit_1.informed_consent_still_valid"
            :label="t('informed_consent_still_valid')"
          />
          <patient-boolean
            displayBlock
            v-model="visit_1.is_patient_diary_checked"
            :label="t('is_patient_diary_checked')"
          />
          <patient-boolean
            displayBlock
            v-model="visit_1.is_patient_reevaluated_rome_4_criteria"
            :label="t('is_patient_reevaluated_rome_4_criteria')"
          />
        </div>
        <appointment v-model="visit_1" />
        <eligibility v-model="visit_1" />
        <daily-diary :visit="1" v-model="visit_1" />
        <div class="row">
          <div class="col-12">
            <h2>{{ t("randomization_title") }}</h2>
          </div>
          <patient-boolean
            v-model="visit_1.randomized_1"
            :label="t('randomized_1')"
            class="mb-4"
          />
          <div class="col-12">
            <base-input
              :label="t('randomized_2')"
              v-model="visit_1.randomized_2"
            >
            </base-input>
          </div>
          <div class="col-12">
            <base-input
              :label="t('sachet_batch_number')"
              v-model="visit_1.sachet_batch_number"
              inputType="number"
            >
            </base-input>
          </div>
          <patient-boolean
            v-model="visit_1.randomized_3"
            :label="t('randomized_3')"
          />
        </div>
        <bss-table v-model="visit_1" />
      </tab-pane>
      <tab-pane :title="t('ibs_sss')">
        <ibs-sss v-model="visit_1.ibs_sss" />
      </tab-pane>
      <tab-pane :title="t('tab_vas')">
        <vas-score v-model="visit_1.vas_score" :visit="visit_1" />
      </tab-pane>
      <tab-pane :title="t('tab_pevs')">
        <pe-vs v-model="visit_1.pe_vs" />
      </tab-pane>
      <tab-pane :title="t('tab_criterias')">
        <criteria v-model="visit_1.criteria" class="mt-4" />
      </tab-pane>
      <tab-pane :title="t('tab_qol')">
        <questionnaire :questionnaire="visit_1.questionnaire" class="mt-4" />
      </tab-pane>
      <tab-pane :title="t('tab_hads')">
        <hads :hads="visit_1.hads" class="mt-4" />
      </tab-pane>
      <tab-pane :title="t('tab_pss')">
        <pss :pss="visit_1.pss" class="mt-4" />
      </tab-pane>
      <tab-pane :title="t('tab_drug')">
        <drugs
          :drugs="visit_1.drugs"
          v-model="visit_1.supplement_usage"
          class="mt-4 mb-4"
        />
      </tab-pane>
      <tab-pane :title="t('product_accountability')">
        <product-accontability
          :product_accountability="visit_1.product_accountability"
        />
      </tab-pane>
      <tab-pane :title="t('protocol_deviations')">
        <protocol-deviations v-model="visit_1.protocol_deviations" />
      </tab-pane>
      <tab-pane :title="t('daily_diaries')">
        <daily-diaries v-model="visit_1.daily_diaries" class="mt-4 mb-4" />
      </tab-pane>
    </tabs>
    <div class="row">
      <div
        class="col-12 mt-4"
        v-if="user.level === 'MASTER' || user.level === 'VIEWER'"
      >
        <div class="d-flex align-items-center">
          <label class="form-control-label">
            {{ t("approve_visit_1") }}:
          </label>
          <base-switch
            @input="updateLockStatus($event)"
            class="ml-2"
            :disabled="!patient.id"
            v-model="lock"
          ></base-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import patientBoolean from "./partials/patientBoolean";
import Questionnaire from "./Questionnaire";
import Hads from "./Hads";
import Pss from "./Pss";
import Drugs from "./Drugs";
import Criteria from "./Criteria.vue";
import Appointment from "./partials/appointment.vue";
import DailyDiary from "./partials/dailyDiary.vue";
import PeVs from "./PeVs.vue";
import VasScore from "./partials/vasScore.vue";
import Eligibility from "./partials/eligibility.vue";
import IbsSss from "./IbsSss.vue";
import ProductAccontability from "./partials/productAccountability/index.vue";
import ProtocolDeviations from "./partials/protocolDeviations.vue";
import BssTable from "./partials/bssTable.vue";
import DailyDiaries from "./partials/dailyDiaries/index.vue";

export default {
  name: "visit1",
  data() {
    return {
      lock: false,
    };
  },
  components: {
    patientBoolean,
    Questionnaire,
    Hads,
    Pss,
    Drugs,
    Criteria,
    Appointment,
    DailyDiary,
    PeVs,
    VasScore,
    Eligibility,
    IbsSss,
    ProductAccontability,
    ProtocolDeviations,
    BssTable,
    DailyDiaries,
  },
  props: {
    visit_1: {
      type: Object,
      required: true,
    },
    lockStatus: {
      type: Boolean,
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    updateLockStatus(val) {
      this.$emit("updateLockStatus", val);
    },
  },
  created() {
    this.lock = this.lockStatus;
  },
};
</script>
