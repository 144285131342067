<template>
  <div class="row my-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h2>{{ t("daily_diary") }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table
            class="
              table
              tablesorter
              table
              table-flush
              diary-table
            "
          >
            <thead>
              <tr>
                <th class="date">{{ t("Date") }}</th>
                <th class="hour">{{ t("Hour") }}</th>
                <th class="bss">{{ t("Bristol Stool Scale") }}</th>
                <th class="vas">{{ t("VAS Point (in last 24 hour)") }}</th>
                <th class="q1">
                  {{
                    t(
                      "Do you feel that you can completely defecate your bowels?"
                    )
                  }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="list">
              <single-diary
                :key="keyDiary"
                :diary="value[keyDiary]"
                @remove="removeDiary(keyDiary)"
                v-for="(diary, keyDiary) in value"
              />
            </tbody>
          </table>
          <base-button
            outline
            type="primary"
            icon="ni ni-fat-add"
            @click="addDiary()"
            class="mt-2"
          >
            {{ t("button_add") }}
          </base-button>
        </div>
      </div>
      <hr class="my-0" />
    </div>
  </div>
</template>
<script>
import SingleDiary from "./singleDiary.vue";

export default {
  name: "dailyDiary",
  components: {
    SingleDiary,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    addDiary() {
      this.value.push({
        date: null,
        hour: null,
        bss_type: null,
        vas_score: null,
        q_1: null,
      });
    },
    removeDiary(index) {
      this.$emit(
        "input",
        this.value.filter((val, key) => key !== index)
      );
    },
  },
  created() {
    if (this.value.length === 0) this.addDiary();
  },
};
</script>
<style>
.diary-table .date {
  min-width: 150px;
}
.diary-table .hour {
  min-width: 110px;
}
.diary-table .bss {
  min-width: 130px;
}
.diary-table .vas {
  min-width: 200px;
}
.diary-table tbody tr .bss{
    display: flex;
    flex-wrap: wrap;
}
</style>