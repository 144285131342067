<template>
    <div class="row">
        <div class="col-12 mt-4 px-4">
            <base-loader
            v-if="
                $wait.is('fetchpatient') ||
                $wait.is('updatePatient') ||
                $wait.is('fetchCenters')
            "
            />
            
            <base-radio
                dataValue="Screen Failure"
                v-model="patient.end_of_treatments"
                class="mr-4 pl-4"
            >
                Screen Failure
            </base-radio>
            <base-radio
                dataValue="Completed"
                v-model="patient.end_of_treatments"
                class="mr-4 pl-4"
            >
                Completed
            </base-radio>
            <base-radio
                dataValue="Drop out"
                v-model="patient.end_of_treatments"
                class="mr-4 pl-4"
            >
                Drop out
            </base-radio>
        </div>
    </div>
</template>
<script>
export default {
    name: "EndofTreatment",
    props: {
    patient: {
      type: Object,
      required: true,
    },
    data(){

    }
  },
};
</script>
