<template>
  <div class="col-12 mt-4">
    <label class="form-control-label">
      {{ t("sss_f7_cross") }}
    </label>
    <div id="body-cross">
      <img src="../../../assets/imgs/body.jpg" @click="setCross($event)" />
      <base-button
        type="success"
        icon="ni ni-active-40"
        size="sm"
        :style="[{ left: getX(cross[0]) }, { top: getY(cross[1]) }]"
        :key="keyCross"
        v-for="(cross, keyCross) in value"
        @click="removeCross(keyCross)"
      ></base-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "bodyCross",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
  },
  methods: {
    setCross(e) {
      const x = e.layerX - 200;
      const y = e.layerY > 200 ? (e.layerY - 200) * -1 : 200 - e.layerY;
      this.model.push([x, y]);
    },
    getX(value) {
      return value + 200 + "px";
    },
    getY(value) {
      return (value < 0 ? value * -1 + 200 : 200 - value) + "px";
    },
    removeCross(crossIndex) {
      this.model.splice(crossIndex, 1);
    },
  },
};
</script>
<style lang="css">
#body-cross {
  display: block;
  margin: 0 auto;
  width: 402px;
  height: 402px;
  cursor: pointer;
  position: relative;
  border: 1px solid #525f7f;
}
#body-cross button {
  position: absolute;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  margin-left: -15px;
  margin-top: -14px;
}
</style>
