<template>
  <modal
    :show="modalStatus"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-sm"
    @close="onModalClosed"
  >
    <template slot="header">
      <h5 class="modal-title" id="exampleModalLabel">
        {{ t("message_delete_confirm") }}
      </h5>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between w-100">
        <base-button type="secondary" @click="onModalClosed">
          {{ t("button_cancel") }}
        </base-button>
        <base-button type="warning" @click="confirm">
          {{ t("button_delete") }}
        </base-button>
      </div>
    </template>
  </modal>
</template>
<script>
export default {
  name: "delete-confirm-modal",
  data() {
    return {};
  },
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onModalClosed() {
      this.$emit("update:modalStatus", false);
    },
    confirm() {
      this.$emit("confirm");
      this.onModalClosed();
    },
  },
};
</script>
