<template>
  <div class="rowmy-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h2>{{ t("Hospital Anxiety and Depression Scale (HADS)") }}</h2>
        </div>
        <is-completed v-model="hads" :label="t('Is HADS Completed?')" />
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f1") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_1" class="mb-2">
            {{ t("Most of the time") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_1" class="mb-2">
            {{ t("A lot of the time") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_1" class="mb-2">
            {{ t("From time to time, occasionally") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_1" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f8") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_8" class="mb-2">
            {{ t("Nearly all the time") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_8" class="mb-2">
            {{ t("Very often") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_8" class="mb-2">
            {{ t("Sometimes") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_8" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f2") }}
          </label>
          <base-radio dataValue="0" v-model="hads.f_2" class="mb-2">
            {{ t("Definitely as much") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_2" class="mb-2">
            {{ t("Not quite so much") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_2" class="mb-2">
            {{ t("Only a little") }}
          </base-radio>
          <base-radio dataValue="3" v-model="hads.f_2" class="mb-2">
            {{ t("Hardly at all") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f9") }}
          </label>
          <base-radio dataValue="0" v-model="hads.f_9" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_9" class="mb-2">
            {{ t("Occasionally") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_9" class="mb-2">
            {{ t("Quite often") }}
          </base-radio>
          <base-radio dataValue="3" v-model="hads.f_9" class="mb-2">
            {{ t("Very often") }}
          </base-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f3") }}
          </label>
          <base-radio dataValue="0" v-model="hads.f_3" class="mb-2">
            {{ t("Very definitely and quite badlyh") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_3" class="mb-2">
            {{ t("Yes, but not too badly") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_3" class="mb-2">
            {{ t("A little, but it doesn't worry me") }}
          </base-radio>
          <base-radio dataValue="4" v-model="hads.f_3" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f10") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_10" class="mb-2">
            {{ t("Definitely") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_10" class="mb-2">
            {{ t("I don't take as much care as I should") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_10" class="mb-2">
            {{ t("I may not take quite as much care") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_10" class="mb-2">
            {{ t("I take just as much care as ever") }}
          </base-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f4") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_4" class="mb-2">
            {{ t("As much as I always could") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_4" class="mb-2">
            {{ t("Not quite so much now") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_4" class="mb-2">
            {{ t("Definitely not so much now") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_4" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f11") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_11" class="mb-2">
            {{ t("Very much indeed") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_11" class="mb-2">
            {{ t("Quite a lot") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_11" class="mb-2">
            {{ t("Not very much") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_11" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f5") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_5" class="mb-2">
            {{ t("A great deal of the time") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_5" class="mb-2">
            {{ t("A lot of the time") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_5" class="mb-2">
            {{ t("From time to time, but not too often") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_5" class="mb-2">
            {{ t("Only occasionally") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f12") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_12" class="mb-2">
            {{ t("Very much indeed") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_12" class="mb-2">
            {{ t("Quite a lot") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_12" class="mb-2">
            {{ t("Not very much") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_12" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f6") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_6" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_6" class="mb-2">
            {{ t("Not often") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_6" class="mb-2">
            {{ t("Sometimes") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_6" class="mb-2">
            {{ t("Most of the time") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f13") }}
          </label>
          <base-radio dataValue="3" v-model="hads.f_13" class="mb-2">
            {{ t("Very much indeed") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_13" class="mb-2">
            {{ t("Quite a lot") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_13" class="mb-2">
            {{ t("Not very much") }}
          </base-radio>
          <base-radio dataValue="0" v-model="hads.f_13" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f7") }}
          </label>
          <base-radio dataValue="0" v-model="hads.f_7" class="mb-2">
            {{ t("Definitely") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_7" class="mb-2">
            {{ t("Usually") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_7" class="mb-2">
            {{ t("Not Often") }}
          </base-radio>
          <base-radio dataValue="3" v-model="hads.f_7" class="mb-2">
            {{ t("Not at all") }}
          </base-radio>
        </div>
        <div class="col-12 col-md-6">
          <label class="form-control-label">
            {{ t("hads_f14") }}
          </label>
          <base-radio dataValue="0" v-model="hads.f_14" class="mb-2">
            {{ t("Often") }}
          </base-radio>
          <base-radio dataValue="1" v-model="hads.f_14" class="mb-2">
            {{ t("Sometimes") }}
          </base-radio>
          <base-radio dataValue="2" v-model="hads.f_14" class="mb-2">
            {{ t("Not often") }}
          </base-radio>
          <base-radio dataValue="3" v-model="hads.f_14" class="mb-2">
            {{ t("Very seldom") }}
          </base-radio>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <h4>{{ t("Total Score") }}: {{ totalScore }}</h4>
        </div>
        <div class="col-6">
          <base-input
            :label="t('Depression (D)')"
            v-model="hads.depression_score"
            inputType="number"
          >
          </base-input>
        </div>
        <div class="col-6">
          <base-input
            :label="t('Anxiety (A)')"
            v-model="hads.anxiety_score"
            inputType="number"
          >
          </base-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import IsCompleted from "./partials/isCompleted.vue";
export default {
  name: "hads",
  data() {
    return {};
  },
  components: {
    IsCompleted,
  },
  props: {
    hads: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    totalScore() {
      let total = 0;
      for (let index = 1; index <= 14; index++) {
        total +=
          this.hads["f_" + index] !== null
            ? parseInt(this.hads["f_" + index])
            : 0;
      }
      return total;
    },
  },
  methods: {},
  created() {},
};
</script>
