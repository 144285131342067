<template>
  <div class="row my-4">
    <div class="col-12">
      <h2>{{ t("compliance") }}</h2>
    </div>
    <div class="col-12">
      <base-date-input
        :label="t('compliance_date')"
        v-model="value.compliance_date"
      />
    </div>
    <div class="col-12">
      <patient-boolean
        class="pl-0 pb-4"
        :label="t('compliance_performad')"
        v-model="value.compliance_performad"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('compliance_amount_taken')"
        v-model="value.compliance_amount_taken"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('compliance_amount_expected')"
        v-model="value.compliance_amount_expected"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('compliance_percent')"
        v-model="value.compliance_percent"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('compliance_comments')"
        v-model="value.compliance_comments"
      />
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./patientBoolean.vue";

export default {
  name: "appointment",
  components: {
    PatientBoolean,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>