<template>
  <div class="row my-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h2>{{ t("CONCOMITANT MEDICATION") }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-control-label">
              {{ t("supplement_usage") }}
            </label>
            <div class="d-flex">
              <base-radio dataValue="0" v-model="model">
                {{ t("no") }}
              </base-radio>
              <base-radio dataValue="1" v-model="model" @input="setTable">
                {{ t("yes") }}
              </base-radio>
            </div>
          </div>
        </div>
        <div class="col-12" v-show="drugsStatus">
          <table class="table tablesorter table align-items-center table-flush">
            <thead>
              <tr>
                <th>{{ t("Name of Drug") }}</th>
                <th>{{ t("Initiation Date") }}</th>
                <th>{{ t("Withdrawal Date") }}</th>
                <th>{{ t("The Last Dose Taken (mg/day) ") }}</th>
                <th>{{ t("Indication/Concomitant Disease") }}</th>
              </tr>
            </thead>
            <tbody class="list">
              <single-drug
                v-for="(drug, keyDrug) in drugs"
                :key="keyDrug"
                :drug="drug"
              />
            </tbody>
          </table>
          <base-button
            outline
            type="primary"
            icon="ni ni-fat-add"
            @click="addDrug()"
            class="mt-2"
          >
            {{ t("button_add") }}
          </base-button>
        </div>
      </div>
      <hr class="my-0" />
    </div>
  </div>
</template>
<script>
import SingleDrug from "./SingleDrug";
import { mapState } from "vuex";
export default {
  name: "drugs",
  data() {
    return {};
  },
  components: {
    SingleDrug,
  },
  props: {
    value: {
      required: true,
    },
    drugs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    drugsStatus() {
      return !!parseInt(this.value);
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    addDrug() {
      this.drugs.push({
        name: "",
        initiation_date: null,
        withdrawal_date: null,
        dose: "",
        disease: "",
      });
    },
    setTable(drugStatus) {
      if (!!parseInt(drugStatus) && this.drugs.length === 0) {
        this.addDrug();
      }
    },
  },
  created() {},
};
</script>
