<template>
  <div class="row my-4">
    <div class="col-12 col-md-6">
      <label class="form-control-label">{{ label }}</label>
    </div>
    <div class="col-12 col-md-6">
      <vue-slider
        v-model="model"
        :data="data"
        :data-value="'value'"
        :data-label="'label'"
      ></vue-slider>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  name: "PaintPercentSlider",
  components: {
    VueSlider,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      default: () => [
        {
          value: 0,
          label: "no distension",
        },
        {
          value: 25,
          label: "not very severe",
        },
        {
          value: 50,
          label: "quite severe",
        },
        {
          value: 75,
          label: "severe",
        },
        {
          value: 100,
          label: "very severe",
        },
      ],
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
  },
};
</script>
