<template>
  <div class="row daily-diary my-4">
    <div class="col-12">
      <h2>{{ t("daily_diary") }}</h2>
    </div>
    <div class="col-12 col-md-6" v-if="visit !== 4">
      <patient-boolean
        classes="px-0"
        :label="t('daily_diary_instructions')"
        v-model="value.daily_diary_checked_distributed_instructions"
      />
    </div>
    <div class="col-12 col-md-6">
      <patient-boolean
        classes="px-0"
        :label="t('daily_diary_checked')"
        v-model="value.daily_diary_checked"
      />
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./patientBoolean.vue";

export default {
  name: "appointment",
  components: {
    PatientBoolean,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    visit: {
      type: Number,
      default: 1
    }
  },
};
</script>
<style lang="scss">
.daily-diary {
  .form-group {
    &.has-label {
      display: flex;
      align-items: center;
      label {
        margin-right: 16px;
      }
    }
  }
}
</style>