<template>
  <div class="row my-2 mb-4" style="width: 100%; margin-left: 0">
    <div class="col-12">
      <patient-boolean
        v-model="value.is_completed"
        :label="label"
        classes="pl-0 mb-2"
      />
    </div>
    <div class="col-12">
      <base-input
        :label="t('note')"
        v-model="value.is_completed_note"
        v-if="value.is_completed === false || value.is_completed === 0"
      />
    </div>
  </div>
</template>
<script>
import PatientBoolean from "./patientBoolean.vue";

export default {
  name: "isCompleted",
  components: {
    PatientBoolean,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      defualt: "Is Completed?",
    },
  },
};
</script>
