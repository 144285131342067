export const Patient = () => {
  return {
    age: 0,
    center_id: null,
    randomization_id: null,
    date: null,
    gender: null,
    occupation: null,
    occupation_other: null,
    patient_approve: null,
    patient_birthdate: null,
    patient_initial_1: null,
    patient_initial_2: null,
    patient_initial_3: null,
    patient_no: null,
    updated_at: null,
    patient_approve_version: null,
    patient_approve_date: null,
    patient_approve_version_other: null,
    patient_approve_version_other_value: null,
    ethnicity: null,
    education: null,
    smoker_status: null,
    alcohol_comsumption: null,
    end_of_treatments: null,
  };
};

export const Visit_0 = () => {
  return {
    subject_confirm_appointment: null,
    subject_confirm_appointment_date: null,
    // daily_diary_checked_distributed_instructions: null,
    // daily_diary_checked: null,
    visit_date: null,
    screening_number: null,
    diseases_or_surgeries: null,
    vas_score: null,
    criteria: {
      field_1: null,
      field_2: null,
      field_3: null,
      field_3_1: null,
      field_3_2: null,
      field_3_3: null,
      field_3_4: null,
      field_4: null,
      field_5: null,
      field_6: null,
      field_7: null,
      field_8: null,
      field_9: null,
      field_10: null,
      field_11: null,
      field_12: null,
      field_13: null,
      field_14: null,
      field_15: null,
      field_16: null,
      field_17: null,
      field_18: null,
      field_19: null,
      field_20: null,
      field_100: null,
      field_101: null,
      field_102: null,
    },
    screening: {
      field_21: null,
      field_22: null,
      field_22_2: null,
      field_23: null,
      field_23_2: null,
      field_24: null,
      field_25: [],
      field_26: null,
      field_27: null,
      field_28: null,
      field_29: null,
      field_30: null,
      field_30_1: null,
      is_completed: null,
      is_completed_note: null,
    },
    lab: {
      blood_sample_collected: null,
      blood_sample_collected_date: null,
      blood_sample_collected_time: null,
      stool_sample_collected: null,
      stool_sample_collected_date: null,
      stool_sample_collected_time: null,
      white_blood_cell_count: null,
      white_blood_cell_count_range: null,
      white_blood_cell_count_significant: null,
      white_blood_cell_count_comment: null,
      hemoglobin: null,
      hemoglobin_range: null,
      hemoglobin_significant: null,
      hemoglobin_comment: null,
      hematocrit: null,
      hematocrit_range: null,
      hematocrit_significant: null,
      hematocrit_comment: null,
      rbc: null,
      rbc_range: null,
      rbc_significant: null,
      rbc_comment: null,
      sedimentation: null,
      sedimentation_range: null,
      sedimentation_significant: null,
      sedimentation_comment: null,
      crp: null,
      crp_range: null,
      crp_significant: null,
      crp_comment: null,
      ast: null,
      ast_range: null,
      ast_significant: null,
      ast_comment: null,
      alp: null,
      alp_range: null,
      alp_significant: null,
      alp_comment: null,
      creatinine: null,
      creatinine_range: null,
      creatinine_significant: null,
      creatinine_comment: null,
      potassium: null,
      potassium_range: null,
      potassium_significant: null,
      potassium_comment: null,
      chloride: null,
      chloride_range: null,
      chloride_significant: null,
      chloride_comment: null,
      free_t4: null,
      free_t4_range: null,
      free_t4_significant: null,
      free_t4_comment: null,
      anti_gliadin_iga: null,
      anti_gliadin_iga_range: null,
      anti_gliadin_iga_significant: null,
      anti_gliadin_iga_comment: null,
      fecal_calprotectin: null,
      fecal_calprotectin_range: null,
      fecal_calprotectin_significant: null,
      fecal_calprotectin_comment: null,
      mcv: null,
      mcv_range: null,
      mcv_significant: null,
      mcv_comment: null,
      mch: null,
      mch_range: null,
      mch_significant: null,
      mch_comment: null,
      mchc: null,
      mchc_range: null,
      mchc_significant: null,
      mchc_comment: null,
      platelets: null,
      platelets_range: null,
      platelets_significant: null,
      platelets_comment: null,
      rdw: null,
      rdw_range: null,
      rdw_significant: null,
      rdw_comment: null,
      alt: null,
      alt_range: null,
      alt_significant: null,
      alt_comment: null,
      ggt: null,
      ggt_range: null,
      ggt_significant: null,
      ggt_comment: null,
      bun: null,
      bun_range: null,
      bun_significant: null,
      bun_comment: null,
      calcium: null,
      calcium_range: null,
      calcium_significant: null,
      calcium_comment: null,
      sodium: null,
      sodium_range: null,
      sodium_significant: null,
      sodium_comment: null,
      free_t3: null,
      free_t3_range: null,
      free_t3_significant: null,
      free_t3_comment: null,
      tsh: null,
      tsh_range: null,
      tsh_significant: null,
      tsh_comment: null,
      tissue_transglutaminase_iga: null,
      tissue_transglutaminase_iga_range: null,
      tissue_transglutaminase_iga_significant: null,
      tissue_transglutaminase_iga_comment: null,
      pregnancy_test: null,
      pregnancy_test_range: null,
      pregnancy_test_significant: null,
      pregnancy_test_comment: null,
      ecg: null,
    },
    pe_vs: {
      pe_vs_was: null,
      pe_vs_date: null,
      pe_vs_time: null,
      pe_vs_height: null,
      pe_vs_weight: null,
      pe_vs_bmi: null,
      pe_vs_hr: null,
      pe_vs_bp: null,
      pe_vs_ga: null,
      pe_vs_neck: null,
      pe_vs_chest_and_lings: null,
      pe_vs_abdomen: null,
      pe_vs_lymph_nodes: null,
      pe_vs_es: null,
      pe_vs_other: null,
    },
    supplement_usage: null,
    bss_table: null,
    drugs: [],
    medical_histories: [],
    bss_is_completed: null,
    bss_is_completed_note: null,
    vas_score_is_completed: null,
    vas_score_is_completed_note: null,
  };
};

export const Visit_1 = () => {
  return {
    subject_confirm_appointment: null,
    subject_confirm_appointment_date: null,
    daily_diary_checked_distributed_instructions: null,
    daily_diary_checked: null,
    field_61: null,
    field_62: null,
    field_63: null,
    criteria: {
      field_1: null,
      field_2: null,
      field_3: null,
      field_3_1: null,
      field_3_2: null,
      field_3_3: null,
      field_3_4: null,
      field_4: null,
      field_5: null,
      field_6: null,
      field_7: null,
      field_8: null,
      field_9: null,
      field_10: null,
      field_11: null,
      field_12: null,
      field_13: null,
      field_14: null,
      field_15: null,
      field_16: null,
      field_17: null,
      field_18: null,
      field_19: null,
      field_20: null,
      field_100: null,
      field_101: null,
      field_102: null,
    },
    questionnaire: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      f_11: null,
      f_12: null,
      f_13: null,
      f_14: null,
      f_15: null,
      f_16: null,
      f_17: null,
      f_18: null,
      f_19: null,
      f_20: null,
      f_21: null,
      f_22: null,
      f_23: null,
      f_24: null,
      f_25: null,
      f_26: null,
      f_27: null,
      f_28: null,
      f_29: null,
      f_30: null,
      f_33: null,
      f_34: null,
      f_35: null,
      is_completed: null,
      is_completed_note: null,
    },
    hads: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      f_11: null,
      f_12: null,
      f_13: null,
      f_14: null,
      depression_score: null,
      anxiety_score: null,
      is_completed: null,
      is_completed_note: null,
    },
    pss: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      is_completed: null,
      is_completed_note: null,
    },
    supplement_usage: null,
    drugs: [],
    randomized_1: null,
    randomized_2: null,
    randomized_3: null,
    sachet_batch_number: null,
    vas_score: null,
    pe_vs: {
      pe_vs_was: null,
      pe_vs_date: null,
      pe_vs_time: null,
      pe_vs_height: null,
      pe_vs_weight: null,
      pe_vs_bmi: null,
      pe_vs_hr: null,
      pe_vs_bp: null,
      pe_vs_ga: null,
      pe_vs_neck: null,
      pe_vs_chest_and_lings: null,
      pe_vs_abdomen: null,
      pe_vs_lymph_nodes: null,
      pe_vs_es: null,
      pe_vs_other: null,
    },
    visit_date: null,
    informed_consent_still_valid: null,
    is_patient_diary_checked: null,
    is_patient_reevaluated_rome_4_criteria: null,
    eligibility_criteria: null,
    exemption_granted: null,
    exemption_reason: null,
    subject_failed_visit: null,
    primary_reason_for_non_eligibility: null,
    visit_2_date: null,
    ibs_sss: {
      f_1_a: null,
      f_1_b: null,
      f_1_c: null,
      f_2_a: null,
      f_2_b: null,
      f_3: null,
      f_4: null,
      f_5_a: null,
      f_5_b: null,
      f_6_normal: null,
      f_6_hard: null,
      f_6_very_thin: null,
      f_6_small_pieces: null,
      f_6_mush: null,
      f_6_watery: null,
      f_7_a: null,
      f_7_b: null,
      f_7_c: null,
      f_7_d: null,
      f_7_e: null,
      f_7_cross: [],
      f_8_a: null,
      f_8_b: null,
      f_9_a: null,
      f_9_b: null,
      is_completed: null,
      is_completed_note: null,
    },
    product_accountability: [],
    protocol_deviations: [],
    bss_table: null,
    bss_is_completed: null,
    bss_is_completed_note: null,
    vas_score_is_completed: null,
    vas_score_is_completed_note: null,
    daily_diaries: [],
  };
};

export const Visit_2 = () => {
  return {
    f_1: null,
    f_2: null,
    f_3: null,
    serious_adverse_events: [],
    supplement_usage: null,
    drugs: [],
    fuc_date: null,
    fuc_q1: null,
    fuc_q2: null,
    protocol_deviations: [],
  };
};

export const Visit_3 = () => {
  return {
    subject_confirm_appointment: null,
    subject_confirm_appointment_date: null,
    daily_diary_checked_distributed_instructions: null,
    daily_diary_checked: null,
    blood_pressure: null,
    pulse_beats_min: null,
    lab: {
      blood_sample_collected: null,
      blood_sample_collected_date: null,
      blood_sample_collected_time: null,
      stool_sample_collected: null,
      stool_sample_collected_date: null,
      stool_sample_collected_time: null,
      white_blood_cell_count: null,
      white_blood_cell_count_range: null,
      white_blood_cell_count_significant: null,
      white_blood_cell_count_comment: null,
      hemoglobin: null,
      hemoglobin_range: null,
      hemoglobin_significant: null,
      hemoglobin_comment: null,
      hematocrit: null,
      hematocrit_range: null,
      hematocrit_significant: null,
      hematocrit_comment: null,
      rbc: null,
      rbc_range: null,
      rbc_significant: null,
      rbc_comment: null,
      sedimentation: null,
      sedimentation_range: null,
      sedimentation_significant: null,
      sedimentation_comment: null,
      crp: null,
      crp_range: null,
      crp_significant: null,
      crp_comment: null,
      ast: null,
      ast_range: null,
      ast_significant: null,
      ast_comment: null,
      alp: null,
      alp_range: null,
      alp_significant: null,
      alp_comment: null,
      creatinine: null,
      creatinine_range: null,
      creatinine_significant: null,
      creatinine_comment: null,
      potassium: null,
      potassium_range: null,
      potassium_significant: null,
      potassium_comment: null,
      chloride: null,
      chloride_range: null,
      chloride_significant: null,
      chloride_comment: null,
      free_t4: null,
      free_t4_range: null,
      free_t4_significant: null,
      free_t4_comment: null,
      anti_gliadin_iga: null,
      anti_gliadin_iga_range: null,
      anti_gliadin_iga_significant: null,
      anti_gliadin_iga_comment: null,
      fecal_calprotectin: null,
      fecal_calprotectin_range: null,
      fecal_calprotectin_significant: null,
      fecal_calprotectin_comment: null,
      mcv: null,
      mcv_range: null,
      mcv_significant: null,
      mcv_comment: null,
      mch: null,
      mch_range: null,
      mch_significant: null,
      mch_comment: null,
      mchc: null,
      mchc_range: null,
      mchc_significant: null,
      mchc_comment: null,
      platelets: null,
      platelets_range: null,
      platelets_significant: null,
      platelets_comment: null,
      rdw: null,
      rdw_range: null,
      rdw_significant: null,
      rdw_comment: null,
      alt: null,
      alt_range: null,
      alt_significant: null,
      alt_comment: null,
      ggt: null,
      ggt_range: null,
      ggt_significant: null,
      ggt_comment: null,
      bun: null,
      bun_range: null,
      bun_significant: null,
      bun_comment: null,
      calcium: null,
      calcium_range: null,
      calcium_significant: null,
      calcium_comment: null,
      sodium: null,
      sodium_range: null,
      sodium_significant: null,
      sodium_comment: null,
      free_t3: null,
      free_t3_range: null,
      free_t3_significant: null,
      free_t3_comment: null,
      tsh: null,
      tsh_range: null,
      tsh_significant: null,
      tsh_comment: null,
      tissue_transglutaminase_iga: null,
      tissue_transglutaminase_iga_range: null,
      tissue_transglutaminase_iga_significant: null,
      tissue_transglutaminase_iga_comment: null,
      pregnancy_test: null,
      pregnancy_test_range: null,
      pregnancy_test_significant: null,
      pregnancy_test_comment: null,
      ecg: null,
    },
    supplement_usage: null,
    drugs: [],
    questionnaire: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      f_11: null,
      f_12: null,
      f_13: null,
      f_14: null,
      f_15: null,
      f_16: null,
      f_17: null,
      f_18: null,
      f_19: null,
      f_20: null,
      f_21: null,
      f_22: null,
      f_23: null,
      f_24: null,
      f_25: null,
      f_26: null,
      f_27: null,
      f_28: null,
      f_29: null,
      f_30: null,
      f_33: null,
      f_34: null,
      f_35: null,
      is_completed: null,
      is_completed_note: null,
    },
    hads: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      f_11: null,
      f_12: null,
      f_13: null,
      f_14: null,
      depression_score: null,
      anxiety_score: null,
      is_completed: null,
      is_completed_note: null,
    },
    pss: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      is_completed: null,
      is_completed_note: null,
    },
    serious_adverse_events_status: null,
    serious_adverse_events: [],
    study_drug_logs: [],
    vas_score: null,
    pe_vs: {
      pe_vs_was: null,
      pe_vs_date: null,
      pe_vs_time: null,
      pe_vs_height: null,
      pe_vs_weight: null,
      pe_vs_bmi: null,
      pe_vs_hr: null,
      pe_vs_bp: null,
      pe_vs_ga: null,
      pe_vs_neck: null,
      pe_vs_chest_and_lings: null,
      pe_vs_abdomen: null,
      pe_vs_lymph_nodes: null,
      pe_vs_es: null,
      pe_vs_other: null,
    },
    ibs_sss: {
      f_1_a: null,
      f_1_b: null,
      f_1_c: null,
      f_2_a: null,
      f_2_b: null,
      f_3: null,
      f_4: null,
      f_5_a: null,
      f_5_b: null,
      f_6_normal: null,
      f_6_hard: null,
      f_6_very_thin: null,
      f_6_small_pieces: null,
      f_6_mush: null,
      f_6_watery: null,
      f_7_a: null,
      f_7_b: null,
      f_7_c: null,
      f_7_d: null,
      f_7_e: null,
      f_7_cross: [],
      f_8_a: null,
      f_8_b: null,
      f_9_a: null,
      f_9_b: null,
      is_completed: null,
      is_completed_note: null,
    },
    product_accountability: [],
    compliance_date: null,
    compliance_performad: null,
    compliance_amount_taken: null,
    compliance_amount_expected: null,
    compliance_percent: null,
    compliance_comments: null,
    protocol_deviations: [],
    bss_table: null,
    bss_is_completed: null,
    bss_is_completed_note: null,
    vas_score_is_completed: null,
    vas_score_is_completed_note: null,
    daily_diaries: [],
  };
};

export const Visit_4 = () => {
  return {
    subject_confirm_appointment: null,
    subject_confirm_appointment_date: null,
    daily_diary_checked_distributed_instructions: null,
    daily_diary_checked: null,
    visit_date: null,
    blood_pressure: null,
    pulse_beats_min: null,
    supplement_usage: null,
    drugs: [],
    serious_adverse_events_status: null,
    serious_adverse_events: [],
    pe_vs: {
      pe_vs_was: null,
      pe_vs_date: null,
      pe_vs_time: null,
      pe_vs_height: null,
      pe_vs_weight: null,
      pe_vs_bmi: null,
      pe_vs_hr: null,
      pe_vs_bp: null,
      pe_vs_ga: null,
      pe_vs_neck: null,
      pe_vs_chest_and_lings: null,
      pe_vs_abdomen: null,
      pe_vs_lymph_nodes: null,
      pe_vs_es: null,
      pe_vs_other: null,
    },
    pss: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      is_completed: null,
      is_completed_note: null,
    },
    ibs_sss: {
      f_1_a: null,
      f_1_b: null,
      f_1_c: null,
      f_2_a: null,
      f_2_b: null,
      f_3: null,
      f_4: null,
      f_5_a: null,
      f_5_b: null,
      f_6_normal: null,
      f_6_hard: null,
      f_6_very_thin: null,
      f_6_small_pieces: null,
      f_6_mush: null,
      f_6_watery: null,
      f_7_a: null,
      f_7_b: null,
      f_7_c: null,
      f_7_d: null,
      f_7_e: null,
      f_7_cross: [],
      f_8_a: null,
      f_8_b: null,
      f_9_a: null,
      f_9_b: null,
      is_completed: null,
      is_completed_note: null,
    },
    questionnaire: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      f_11: null,
      f_12: null,
      f_13: null,
      f_14: null,
      f_15: null,
      f_16: null,
      f_17: null,
      f_18: null,
      f_19: null,
      f_20: null,
      f_21: null,
      f_22: null,
      f_23: null,
      f_24: null,
      f_25: null,
      f_26: null,
      f_27: null,
      f_28: null,
      f_29: null,
      f_30: null,
      f_33: null,
      f_34: null,
      f_35: null,
      is_completed: null,
      is_completed_note: null,
    },
    protocol_deviations: [],
    hads: {
      f_1: null,
      f_2: null,
      f_3: null,
      f_4: null,
      f_5: null,
      f_6: null,
      f_7: null,
      f_8: null,
      f_9: null,
      f_10: null,
      f_11: null,
      f_12: null,
      f_13: null,
      f_14: null,
      depression_score: null,
      anxiety_score: null,
      is_completed: null,
      is_completed_note: null,
    },
    daily_diaries: [],
  };
};
