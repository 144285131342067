<template>
    <tr>
      <td>
        <base-date-input
          v-model="diary.date"
          :label="t('')"
        />
      </td>
      <td>
        <input
          class="form-control"
          type="text"
          :label="t('time')"
          v-model="diary.hour"
          v-mask="'##:##'"
          placeholder="00:00"
        />
      </td>
      <td>
        <div class="bss">
          <base-radio dataValue="type 1" v-model="diary.bss_type" class="my-1">
            {{ t("1") }}
          </base-radio>
          <base-radio dataValue="type 2" v-model="diary.bss_type" class="my-1">
            {{ t("2") }}
          </base-radio>
          <base-radio dataValue="type 3" v-model="diary.bss_type" class="my-1">
            {{ t("3") }}
          </base-radio>
          <base-radio dataValue="type 4" v-model="diary.bss_type" class="my-1">
            {{ t("4") }}
          </base-radio>
          <base-radio dataValue="type 5" v-model="diary.bss_type" class="my-1">
            {{ t("5") }}
          </base-radio>
          <base-radio dataValue="type 6" v-model="diary.bss_type" class="my-1">
            {{ t("6") }}
          </base-radio>
          <base-radio dataValue="type 7" v-model="diary.bss_type" class="my-1">
            {{ t("7") }}
          </base-radio>
        </div>
      </td>
      <td>
        <vue-slider
          v-model="diary.vas_score"
          :min="0"
          :max="10"
          :marks="true"
          tooltip="always"
          type="number"
        ></vue-slider>
      </td>
      <td>
        <patient-boolean v-model="diary.q_1" :label="''" />
      </td>
      <td>
        <base-button
          outline
          type="danger"
          icon="ni ni-fat-remove"
          size="sm"
          @click="remove()"
        >
        </base-button>
      </td>
    </tr>
</template>
<script>
import patientBoolean from "../patientBoolean.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "single-diary",
  data() {
    return {};
  },
  components: {
    patientBoolean,
    VueSlider,
  },
  props: {
    diary: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    remove() {
      this.$emit("remove");
    },
  },
  created() {},
};
</script>